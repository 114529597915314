@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-top {
  0% {
    transform-origin: 50% 0;
    transform: scale(.5);
  }

  100% {
    transform-origin: 50% 0;
    transform: scale(1);
  }
}

@keyframes scale-up-tr {
  0% {
    transform-origin: 100% 0;
    transform: scale(.5);
  }

  100% {
    transform-origin: 100% 0;
    transform: scale(1);
  }
}

@keyframes scale-up-right {
  0% {
    transform-origin: 100%;
    transform: scale(.5);
  }

  100% {
    transform-origin: 100%;
    transform: scale(1);
  }
}

@keyframes scale-up-br {
  0% {
    transform-origin: 100% 100%;
    transform: scale(.5);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scale(1);
  }
}

@keyframes scale-up-bottom {
  0% {
    transform-origin: 50% 100%;
    transform: scale(.5);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes scale-up-bl {
  0% {
    transform-origin: 0 100%;
    transform: scale(.5);
  }

  100% {
    transform-origin: 0 100%;
    transform: scale(1);
  }
}

@keyframes scale-up-left {
  0% {
    transform-origin: 0;
    transform: scale(.5);
  }

  100% {
    transform-origin: 0;
    transform: scale(1);
  }
}

@keyframes scale-up-tl {
  0% {
    transform-origin: 0 0;
    transform: scale(.5);
  }

  100% {
    transform-origin: 0 0;
    transform: scale(1);
  }
}

@keyframes scale-up-hor-center {
  0% {
    transform: scaleX(.4);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes scale-up-hor-left {
  0% {
    transform-origin: 0 0;
    transform: scaleX(.4);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleX(1);
  }
}

@keyframes scale-up-hor-right {
  0% {
    transform-origin: 100% 100%;
    transform: scaleX(.4);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scaleX(1);
  }
}

@keyframes scale-up-ver-center {
  0% {
    transform: scaleY(.4);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes scale-up-ver-top {
  0% {
    transform-origin: 100% 0;
    transform: scaleY(.4);
  }

  100% {
    transform-origin: 100% 0;
    transform: scaleY(1);
  }
}

@keyframes scale-up-ver-bottom {
  0% {
    transform-origin: 0 100%;
    transform: scaleY(.4);
  }

  100% {
    transform-origin: 0 100%;
    transform: scaleY(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.5);
  }
}

@keyframes scale-down-top {
  0% {
    transform-origin: 50% 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 0;
    transform: scale(.5);
  }
}

@keyframes scale-down-tr {
  0% {
    transform-origin: 100% 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 0;
    transform: scale(.5);
  }
}

@keyframes scale-down-right {
  0% {
    transform-origin: 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 100%;
    transform: scale(.5);
  }
}

@keyframes scale-down-br {
  0% {
    transform-origin: 100% 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scale(.5);
  }
}

@keyframes scale-down-bottom {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(.5);
  }
}

@keyframes scale-down-bl {
  0% {
    transform-origin: 0 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 100%;
    transform: scale(.5);
  }
}

@keyframes scale-down-left {
  0% {
    transform-origin: 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 0;
    transform: scale(.5);
  }
}

@keyframes scale-down-tl {
  0% {
    transform-origin: 0 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 0;
    transform: scale(.5);
  }
}

@keyframes scale-down-hor-center {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(.3);
  }
}

@keyframes scale-down-hor-left {
  0% {
    transform-origin: 0 0;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleX(.3);
  }
}

@keyframes scale-down-hor-right {
  0% {
    transform-origin: 100% 100%;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scaleX(.3);
  }
}

@keyframes scale-down-ver-center {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(.3);
  }
}

@keyframes scale-down-ver-top {
  0% {
    transform-origin: 100% 0;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 100% 0;
    transform: scaleY(.3);
  }
}

@keyframes scale-down-ver-bottom {
  0% {
    transform-origin: 0 100%;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 0 100%;
    transform: scaleY(.3);
  }
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-top {
  0% {
    transform-origin: top;
    transform: rotate(0);
  }

  100% {
    transform-origin: top;
    transform: rotate(360deg);
  }
}

@keyframes rotate-tr {
  0% {
    transform-origin: 100% 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate(360deg);
  }
}

@keyframes rotate-right {
  0% {
    transform-origin: 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotate(360deg);
  }
}

@keyframes rotate-br {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate(360deg);
  }
}

@keyframes rotate-bottom {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotate(360deg);
  }
}

@keyframes rotate-bl {
  0% {
    transform-origin: 0 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate(360deg);
  }
}

@keyframes rotate-left {
  0% {
    transform-origin: 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0;
    transform: rotate(360deg);
  }
}

@keyframes rotate-tl {
  0% {
    transform-origin: 0 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate(360deg);
  }
}

@keyframes rotate-hor-center {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-360deg);
  }
}

@keyframes rotate-hor-top {
  0% {
    transform-origin: top;
    transform: rotateX(0);
  }

  100% {
    transform-origin: top;
    transform: rotateX(-360deg);
  }
}

@keyframes rotate-hor-bottom {
  0% {
    transform-origin: bottom;
    transform: rotateX(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotateX(360deg);
  }
}

@keyframes rotate-vert-center {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-left {
  0% {
    transform-origin: 0;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-right {
  0% {
    transform-origin: 100%;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotateY(-360deg);
  }
}

@keyframes rotate-diagonal-1 {
  0% {
    transform: rotate3d(1, 1, 0, 0);
  }

  50% {
    transform: rotate3d(1, 1, 0, -180deg);
  }

  100% {
    transform: rotate3d(1, 1, 0, -360deg);
  }
}

@keyframes rotate-diagonal-2 {
  0% {
    transform: rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform: rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    transform: rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-diagonal-tr {
  0% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, 0);
  }

  50% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, -180deg);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, -360deg);
  }
}

@keyframes rotate-diagonal-br {
  0% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, -180deg);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, -360deg);
  }
}

@keyframes rotate-diagonal-bl {
  0% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 0);
  }

  50% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 180deg);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 360deg);
  }
}

@keyframes rotate-diagonal-tl {
  0% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    transform: scale(1)rotateZ(0);
  }

  50% {
    transform: scale(2)rotateZ(180deg);
  }

  100% {
    transform: scale(1)rotateZ(360deg);
  }
}

@keyframes rotate-scale-down {
  0% {
    transform: scale(1)rotateZ(0);
  }

  50% {
    transform: scale(.5)rotateZ(180deg);
  }

  100% {
    transform: scale(1)rotateZ(360deg);
  }
}

@keyframes rotate-scale-up-hor {
  0% {
    transform: scale(1)rotateX(0);
  }

  50% {
    transform: scale(2)rotateX(-180deg);
  }

  100% {
    transform: scale(1)rotateX(-360deg);
  }
}

@keyframes rotate-scale-down-hor {
  0% {
    transform: scale(1)rotateX(0);
  }

  50% {
    transform: scale(.5)rotateX(-180deg);
  }

  100% {
    transform: scale(1)rotateX(-360deg);
  }
}

@keyframes rotate-scale-up-ver {
  0% {
    transform: scale(1)rotateY(0);
  }

  50% {
    transform: scale(2)rotateY(180deg);
  }

  100% {
    transform: scale(1)rotateY(360deg);
  }
}

@keyframes rotate-scale-down-ver {
  0% {
    transform: scale(1)rotateY(0);
  }

  50% {
    transform: scale(.5)rotateY(180deg);
  }

  100% {
    transform: scale(1)rotateY(360deg);
  }
}

@keyframes rotate-scale-up-diag-1 {
  0% {
    transform: scale(1)rotate3d(1, 1, 0, 0);
  }

  50% {
    transform: scale(2)rotate3d(1, 1, 0, -180deg);
  }

  100% {
    transform: scale(1)rotate3d(1, 1, 0, -360deg);
  }
}

@keyframes rotate-scale-down-diag-1 {
  0% {
    transform: scale(1)rotate3d(1, 1, 0, 0);
  }

  50% {
    transform: scale(.5)rotate3d(1, 1, 0, -180deg);
  }

  100% {
    transform: scale(1)rotate3d(1, 1, 0, -360deg);
  }
}

@keyframes rotate-scale-up-diag-2 {
  0% {
    transform: scale(1)rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform: scale(2)rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    transform: scale(1)rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-scale-down-diag-2 {
  0% {
    transform: scale(1)rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform: scale(.5)rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    transform: scale(1)rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-90-cw {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-ccw {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-top-cw {
  0% {
    transform-origin: top;
    transform: rotate(0);
  }

  100% {
    transform-origin: top;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-top-ccw {
  0% {
    transform-origin: top;
    transform: rotate(0);
  }

  100% {
    transform-origin: top;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-tr-cw {
  0% {
    transform-origin: 100% 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-tr-ccw {
  0% {
    transform-origin: 100% 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-right-cw {
  0% {
    transform-origin: 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-right-ccw {
  0% {
    transform-origin: 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-br-cw {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-br-ccw {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-bottom-cw {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-bottom-ccw {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-bl-cw {
  0% {
    transform-origin: 0 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-bl-ccw {
  0% {
    transform-origin: 0 100%;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-left-cw {
  0% {
    transform-origin: 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-left-ccw {
  0% {
    transform-origin: 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-tl-cw {
  0% {
    transform-origin: 0 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-tl-ccw {
  0% {
    transform-origin: 0 0;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate(-90deg);
  }
}

@keyframes rotate-90-horizontal-fwd {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(90deg);
  }
}

@keyframes rotate-90-horizontal-bck {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes rotate-90-vertical-fwd {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotate-90-vertical-bck {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(-90deg);
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes flip-horizontal-top {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes flip-horizontal-bck {
  0% {
    transform: translateZ(0)rotateX(0);
  }

  100% {
    transform: translateZ(-260px)rotateX(180deg);
  }
}

@keyframes flip-horizontal-fwd {
  0% {
    transform: translateZ(0)rotateX(0);
  }

  100% {
    transform: translateZ(160px)rotateX(-180deg);
  }
}

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(180deg);
  }
}

@keyframes flip-vertical-left {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(-180deg);
  }
}

@keyframes flip-vertical-bck {
  0% {
    transform: translateZ(0)rotateY(0);
  }

  100% {
    transform: translateZ(-260px)rotateY(-180deg);
  }
}

@keyframes flip-vertical-fwd {
  0% {
    transform: translateZ(0)rotateY(0);
  }

  100% {
    transform: translateZ(160px)rotateY(180deg);
  }
}

@keyframes flip-diagonal-1-tr {
  0% {
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform: rotate3d(1, 1, 0, 180deg);
  }
}

@keyframes flip-diagonal-1-bl {
  0% {
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform: rotate3d(1, 1, 0, -180deg);
  }
}

@keyframes flip-diagonal-1-bck {
  0% {
    transform: translateZ(0)rotate3d(1, 1, 0, 0);
  }

  100% {
    transform: translateZ(-260px)rotate3d(1, 1, 0, -180deg);
  }
}

@keyframes flip-diagonal-1-fwd {
  0% {
    transform: translateZ(0)rotate3d(1, 1, 0, 0);
  }

  100% {
    transform: translateZ(160px)rotate3d(1, 1, 0, 180deg);
  }
}

@keyframes flip-diagonal-2-br {
  0% {
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform: rotate3d(-1, 1, 0, 180deg);
  }
}

@keyframes flip-diagonal-2-tl {
  0% {
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform: rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes flip-diagonal-2-bck {
  0% {
    transform: translateZ(0)rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform: translateZ(-260px)rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes flip-diagonal-2-fwd {
  0% {
    transform: translateZ(0)rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform: translateZ(160px)rotate3d(-1, 1, 0, 180deg);
  }
}

@keyframes flip-2-hor-top-1 {
  0% {
    transform-origin: 50% 0;
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: translateY(-100%)rotateX(-180deg);
  }
}

@keyframes flip-2-hor-top-2 {
  0% {
    transform-origin: 50% 0;
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: translateY(-100%)rotateX(180deg);
  }
}

@keyframes flip-2-hor-top-bck {
  0% {
    transform-origin: 50% 0;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: translateY(-100%)translateZ(-260px)rotateX(180deg);
  }
}

@keyframes flip-2-hor-top-fwd {
  0% {
    transform-origin: 50% 0;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: translateY(-100%)translateZ(160px)rotateX(-180deg);
  }
}

@keyframes flip-2-ver-right-1 {
  0% {
    transform-origin: 100%;
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(100%)rotateY(-180deg);
  }
}

@keyframes flip-2-ver-right-2 {
  0% {
    transform-origin: 100%;
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(100%)rotateY(180deg);
  }
}

@keyframes flip-2-ver-right-bck {
  0% {
    transform-origin: 100%;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(100%)translateZ(-260px)rotateY(180deg);
  }
}

@keyframes flip-2-ver-right-fwd {
  0% {
    transform-origin: 100%;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(100%)translateZ(160px)rotateY(-180deg);
  }
}

@keyframes flip-2-hor-bottom-1 {
  0% {
    transform-origin: 50% 100%;
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 0;
    transform: translateY(100%)rotateX(180deg);
  }
}

@keyframes flip-2-hor-bottom-2 {
  0% {
    transform-origin: 50% 100%;
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 0;
    transform: translateY(100%)rotateX(-180deg);
  }
}

@keyframes flip-2-hor-bottom-bck {
  0% {
    transform-origin: 50% 100%;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 0;
    transform: translateY(100%)translateZ(-260px)rotateX(-180deg);
  }
}

@keyframes flip-2-hor-bottom-fwd {
  0% {
    transform-origin: 50% 100%;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: 50% 0;
    transform: translateY(100%)translateZ(160px)rotateX(180deg);
  }
}

@keyframes flip-2-ver-left-1 {
  0% {
    transform-origin: 0;
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%)rotateY(180deg);
  }
}

@keyframes flip-2-ver-left-2 {
  0% {
    transform-origin: 0;
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%)rotateY(-180deg);
  }
}

@keyframes flip-2-ver-left-bck {
  0% {
    transform-origin: 0;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%)translateZ(-260px)rotateY(-180deg);
  }
}

@keyframes flip-2-ver-left-fwd {
  0% {
    transform-origin: 0;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%)translateZ(160px)rotateY(180deg);
  }
}

@keyframes flip-scale-up-hor {
  0% {
    transform: scale(1)rotateX(0);
  }

  50% {
    transform: scale(2.5)rotateX(-90deg);
  }

  100% {
    transform: scale(1)rotateX(-180deg);
  }
}

@keyframes flip-scale-down-hor {
  0% {
    transform: scale(1)rotateX(0);
  }

  50% {
    transform: scale(.4)rotateX(90deg);
  }

  100% {
    transform: scale(1)rotateX(180deg);
  }
}

@keyframes flip-scale-up-ver {
  0% {
    transform: scale(1)rotateY(0);
  }

  50% {
    transform: scale(2.5)rotateY(90deg);
  }

  100% {
    transform: scale(1)rotateY(180deg);
  }
}

@keyframes flip-scale-down-ver {
  0% {
    transform: scale(1)rotateY(0);
  }

  50% {
    transform: scale(.4)rotateY(-90deg);
  }

  100% {
    transform: scale(1)rotateY(-180deg);
  }
}

@keyframes flip-scale-up-diag-1 {
  0% {
    transform: scale(1)rotate3d(1, 1, 0, 0);
  }

  50% {
    transform: scale(2.5)rotate3d(1, 1, 0, 90deg);
  }

  100% {
    transform: scale(1)rotate3d(1, 1, 0, 180deg);
  }
}

@keyframes flip-scale-down-diag-1 {
  0% {
    transform: scale(1)rotate3d(1, 1, 0, 0);
  }

  50% {
    transform: scale(.4)rotate3d(1, 1, 0, -90deg);
  }

  100% {
    transform: scale(1)rotate3d(1, 1, 0, -180deg);
  }
}

@keyframes flip-scale-up-diag-2 {
  0% {
    transform: scale(1)rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform: scale(2.5)rotate3d(-1, 1, 0, 90deg);
  }

  100% {
    transform: scale(1)rotate3d(-1, 1, 0, 180deg);
  }
}

@keyframes flip-scale-down-diag-2 {
  0% {
    transform: scale(1)rotate3d(-1, 1, 0, 0);
  }

  50% {
    transform: scale(.4)rotate3d(-1, 1, 0, -90deg);
  }

  100% {
    transform: scale(1)rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes flip-scale-2-hor-top {
  0% {
    transform-origin: 50% 0;
    transform: translateY(0)rotateX(0)scale(1);
  }

  50% {
    transform-origin: 50%;
    transform: translateY(-50%)rotateX(-90deg)scale(2);
  }

  100% {
    transform-origin: 50% 100%;
    transform: translateY(-100%)rotateX(-180deg)scale(1);
  }
}

@keyframes flip-scale-2-ver-right {
  0% {
    transform-origin: 100%;
    transform: translateX(0)rotateY(0)scale(1);
  }

  50% {
    transform-origin: 50%;
    transform: translateX(50%)rotateY(-90deg)scale(2);
  }

  100% {
    transform-origin: 0;
    transform: translateX(100%)rotateY(-180deg)scale(1);
  }
}

@keyframes flip-scale-2-hor-bottom {
  0% {
    transform-origin: 50% 100%;
    transform: translateY(0)rotateX(0)scale(1);
  }

  50% {
    transform-origin: 50%;
    transform: translateY(50%)rotateX(90deg)scale(2);
  }

  100% {
    transform-origin: 50% 0;
    transform: translateY(100%)rotateX(180deg)scale(1);
  }
}

@keyframes flip-scale-2-ver-left {
  0% {
    transform-origin: 0;
    transform: translateX(0)rotateY(0)scale(1);
  }

  50% {
    transform-origin: 50%;
    transform: translateX(-50%)rotateY(90deg)scale(2);
  }

  100% {
    transform-origin: 100%;
    transform: translateX(-100%)rotateY(180deg)scale(1);
  }
}

@keyframes swing-top-fwd {
  0% {
    transform-origin: top;
    transform: rotateX(0);
  }

  100% {
    transform-origin: top;
    transform: rotateX(180deg);
  }
}

@keyframes swing-top-bck {
  0% {
    transform-origin: top;
    transform: rotateX(0);
  }

  100% {
    transform-origin: top;
    transform: rotateX(-180deg);
  }
}

@keyframes swing-top-right-fwd {
  0% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, 180deg);
  }
}

@keyframes swing-top-right-bck {
  0% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform-origin: 100% 0;
    transform: rotate3d(1, 1, 0, -180deg);
  }
}

@keyframes swing-right-fwd {
  0% {
    transform-origin: 100%;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotateY(180deg);
  }
}

@keyframes swing-right-bck {
  0% {
    transform-origin: 100%;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 100%;
    transform: rotateY(-180deg);
  }
}

@keyframes swing-bottom-right-fwd {
  0% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, 180deg);
  }
}

@keyframes swing-bottom-right-bck {
  0% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes swing-bottom-fwd {
  0% {
    transform-origin: bottom;
    transform: rotateX(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotateX(-180deg);
  }
}

@keyframes swing-bottom-bck {
  0% {
    transform-origin: bottom;
    transform: rotateX(0);
  }

  100% {
    transform-origin: bottom;
    transform: rotateX(180deg);
  }
}

@keyframes swing-bottom-left-fwd {
  0% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, -180deg);
  }
}

@keyframes swing-bottom-left-bck {
  0% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotate3d(1, 1, 0, 180deg);
  }
}

@keyframes swing-left-fwd {
  0% {
    transform-origin: 0 100%;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotateY(-180deg);
  }
}

@keyframes swing-left-bck {
  0% {
    transform-origin: 0 100%;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: rotateY(180deg);
  }
}

@keyframes swing-top-left-fwd {
  0% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes swing-top-left-bck {
  0% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 0);
  }

  100% {
    transform-origin: 0 0;
    transform: rotate3d(-1, 1, 0, 180deg);
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

@keyframes slide-tr {
  0% {
    transform: translateY(0)translateX(0);
  }

  100% {
    transform: translateY(-100px)translateX(100px);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100px);
  }
}

@keyframes slide-br {
  0% {
    transform: translateY(0)translateX(0);
  }

  100% {
    transform: translateY(100px)translateX(100px);
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100px);
  }
}

@keyframes slide-bl {
  0% {
    transform: translateY(0)translateX(0);
  }

  100% {
    transform: translateY(100px)translateX(-100px);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100px);
  }
}

@keyframes slide-tl {
  0% {
    transform: translateY(0)translateX(0);
  }

  100% {
    transform: translateY(-100px)translateX(-100px);
  }
}

@keyframes slide-bck-center {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translateZ(-400px);
  }
}

@keyframes slide-bck-top {
  0% {
    transform: translateZ(0)translateY(0);
  }

  100% {
    transform: translateZ(-400px)translateY(-200px);
  }
}

@keyframes slide-bck-tr {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateY(-200px)translateX(200px);
  }
}

@keyframes slide-bck-right {
  0% {
    transform: translateZ(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateX(200px);
  }
}

@keyframes slide-bck-br {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateY(200px)translateX(200px);
  }
}

@keyframes slide-bck-bottom {
  0% {
    transform: translateZ(0)translateY(0);
  }

  100% {
    transform: translateZ(-400px)translateY(200px);
  }
}

@keyframes slide-bck-bl {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateY(200px)translateX(-200px);
  }
}

@keyframes slide-bck-left {
  0% {
    transform: translateZ(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateX(-200px);
  }
}

@keyframes slide-bck-tl {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(-400px)translateY(-200px)translateX(-200px);
  }
}

@keyframes slide-fwd-center {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translateZ(160px);
  }
}

@keyframes slide-fwd-top {
  0% {
    transform: translateZ(0)translateY(0);
  }

  100% {
    transform: translateZ(160px)translateY(-100px);
  }
}

@keyframes slide-fwd-tr {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateY(-100px)translateX(100px);
  }
}

@keyframes slide-fwd-right {
  0% {
    transform: translateZ(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateX(100px);
  }
}

@keyframes slide-fwd-br {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateY(100px)translateX(100px);
  }
}

@keyframes slide-fwd-bottom {
  0% {
    transform: translateZ(0)translateY(0);
  }

  100% {
    transform: translateZ(160px)translateY(100px);
  }
}

@keyframes slide-fwd-bl {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateY(100px)translateX(-100px);
  }
}

@keyframes slide-fwd-left {
  0% {
    transform: translateZ(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateX(-100px);
  }
}

@keyframes slide-fwd-tl {
  0% {
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    transform: translateZ(160px)translateY(-100px)translateX(-100px);
  }
}

@keyframes slide-rotate-hor-top {
  0% {
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform: translateY(-150px)rotateX(-90deg);
  }
}

@keyframes slide-rotate-hor-t-bck {
  0% {
    transform-origin: top;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: top;
    transform: translateY(-150px)translateZ(-230px)rotateX(-90deg);
  }
}

@keyframes slide-rotate-hor-t-fwd {
  0% {
    transform-origin: bottom;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: bottom;
    transform: translateY(-150px)translateZ(130px)rotateX(-90deg);
  }
}

@keyframes slide-rotate-ver-right {
  0% {
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform: translateX(150px)rotateY(-90deg);
  }
}

@keyframes slide-rotate-ver-r-bck {
  0% {
    transform-origin: 100%;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 100%;
    transform: translateX(150px)translateZ(-230px)rotateY(-90deg);
  }
}

@keyframes slide-rotate-ver-r-fwd {
  0% {
    transform-origin: 0;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(150px)translateZ(130px)rotateY(-90deg);
  }
}

@keyframes slide-rotate-hor-bottom {
  0% {
    transform: translateY(0)rotateX(0);
  }

  100% {
    transform: translateY(150px)rotateX(90deg);
  }
}

@keyframes slide-rotate-hor-b-bck {
  0% {
    transform-origin: bottom;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: bottom;
    transform: translateY(150px)translateZ(-230px)rotateX(90deg);
  }
}

@keyframes slide-rotate-hor-b-fwd {
  0% {
    transform-origin: top;
    transform: translateY(0)translateZ(0)rotateX(0);
  }

  100% {
    transform-origin: top;
    transform: translateY(150px)translateZ(130px)rotateX(90deg);
  }
}

@keyframes slide-rotate-ver-left {
  0% {
    transform: translateX(0)rotateY(0);
  }

  100% {
    transform: translateX(-150px)rotateY(90deg);
  }
}

@keyframes slide-rotate-ver-l-bck {
  0% {
    transform-origin: 0;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 0;
    transform: translateX(-150px)translateZ(-230px)rotateY(90deg);
  }
}

@keyframes slide-rotate-ver-l-fwd {
  0% {
    transform-origin: 100%;
    transform: translateX(0)translateZ(0)rotateY(0);
  }

  100% {
    transform-origin: 100%;
    transform: translateX(-150px)translateZ(130px)rotateY(90deg);
  }
}

@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 0 0 20px #00000059;
  }
}

@keyframes shadow-drop-top {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 0 -12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-right {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-bottom {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 0 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-left {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: -12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-lr {
  0% {
    box-shadow: 0 0 #0000, 0 0 #0000;
  }

  100% {
    box-shadow: -12px 0 20px -12px #00000059, 12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-tb {
  0% {
    box-shadow: 0 0 #0000, 0 0 #0000;
  }

  100% {
    box-shadow: 0 -12px 20px -12px #00000059, 0 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-tr {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 12px -12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-br {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 12px 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-bl {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: -12px 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-tl {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: -12px -12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px #00000059;
  }
}

@keyframes shadow-drop-2-top {
  0% {
    transform: translateZ(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateY(12px);
    box-shadow: 0 -12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-right {
  0% {
    transform: translateZ(0)translateX(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(-12px);
    box-shadow: 12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-bottom {
  0% {
    transform: translateZ(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateY(-12px);
    box-shadow: 0 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-left {
  0% {
    transform: translateZ(0)translateX(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(12px);
    box-shadow: -12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-lr {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 #0000, 0 0 #0000;
  }

  100% {
    transform: translateZ(50px);
    box-shadow: -12px 0 20px -12px #00000059, 12px 0 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-tb {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 #0000, 0 0 #0000;
  }

  100% {
    transform: translateZ(50px);
    box-shadow: 0 -12px 20px -12px #00000059, 0 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-tr {
  0% {
    transform: translateZ(0)translateX(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(-12px)translateY(12px);
    box-shadow: 12px -12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-br {
  0% {
    transform: translateZ(0)translateX(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(-12px)translateY(-12px);
    box-shadow: 12px 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-bl {
  0% {
    transform: translateZ(0)translateX(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(12px)translateY(-12px);
    box-shadow: -12px 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-tl {
  0% {
    transform: translateZ(0)translateX(0)translateY(0);
    box-shadow: 0 0 #0000;
  }

  100% {
    transform: translateZ(50px)translateX(12px)translateY(12px);
    box-shadow: -12px -12px 20px -12px #00000059;
  }
}

@keyframes shadow-pop-tr {
  0% {
    transform: translateX(0)translateY(0);
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
  }

  100% {
    transform: translateX(-8px)translateY(8px);
    box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
  }
}

@keyframes shadow-pop-br {
  0% {
    transform: translateX(0)translateY(0);
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
  }

  100% {
    transform: translateX(-8px)translateY(-8px);
    box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
  }
}

@keyframes shadow-pop-bl {
  0% {
    transform: translateX(0)translateY(0);
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
  }

  100% {
    transform: translateX(8px)translateY(-8px);
    box-shadow: -1px 1px #3e3e3e, -2px 2px #3e3e3e, -3px 3px #3e3e3e, -4px 4px #3e3e3e, -5px 5px #3e3e3e, -6px 6px #3e3e3e, -7px 7px #3e3e3e, -8px 8px #3e3e3e;
  }
}

@keyframes shadow-pop-tl {
  0% {
    transform: translateX(0)translateY(0);
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
  }

  100% {
    transform: translateX(8px)translateY(8px);
    box-shadow: -1px -1px #3e3e3e, -2px -2px #3e3e3e, -3px -3px #3e3e3e, -4px -4px #3e3e3e, -5px -5px #3e3e3e, -6px -6px #3e3e3e, -7px -7px #3e3e3e, -8px -8px #3e3e3e;
  }
}

@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 0 0 14px #00000080;
  }
}

@keyframes shadow-inset-top {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 0 6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-right {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset -6px 0 14px -6px #00000080;
  }
}

@keyframes shadow-inset-bottom {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 0 -6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-left {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 6px 0 14px -6px #00000080;
  }
}

@keyframes shadow-inset-lr {
  0% {
    box-shadow: inset 0 0 #0000, inset 0 0 #0000;
  }

  100% {
    box-shadow: inset -6px 0 14px -6px #00000080, inset 6px 0 14px -6px #00000080;
  }
}

@keyframes shadow-inset-tb {
  0% {
    box-shadow: inset 0 0 #0000, inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 0 -6px 14px -6px #00000080, inset 0 6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-tr {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset -6px 6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-br {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset -6px -6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-bl {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 6px -6px 14px -6px #00000080;
  }
}

@keyframes shadow-inset-tl {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 6px 6px 14px -6px #00000080;
  }
}

@keyframes scale-in-center {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-top {
  0% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-tr {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-right {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-br {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-bottom {
  0% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-bl {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-left {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-tl {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in-hor-center {
  0% {
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes scale-in-hor-left {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes scale-in-hor-right {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes scale-in-ver-center {
  0% {
    opacity: 1;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes scale-in-ver-top {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleY(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes scale-in-ver-bottom {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scaleY(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes rotate-in-center {
  0% {
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-top {
  0% {
    transform-origin: top;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: top;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-tr {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-right {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-br {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-bottom {
  0% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-bl {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-left {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-tl {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-hor {
  0% {
    opacity: 0;
    transform: rotateX(360deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes rotate-in-ver {
  0% {
    opacity: 0;
    transform: rotateY(-360deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes rotate-in-diag-1 {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, -360deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes rotate-in-diag-2 {
  0% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, -360deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(-1, 1, 0, 0);
  }
}

@keyframes rotate-in-2-cw {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-ccw {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-fwd-cw {
  0% {
    opacity: 0;
    transform: translateZ(-200px)rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }
}

@keyframes rotate-in-2-fwd-ccw {
  0% {
    opacity: 0;
    transform: translateZ(-200px)rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }
}

@keyframes rotate-in-2-bck-cw {
  0% {
    opacity: 0;
    transform: translateZ(200px)rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }
}

@keyframes rotate-in-2-bck-ccw {
  0% {
    opacity: 0;
    transform: translateZ(200px)rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }
}

@keyframes rotate-in-2-tr-cw {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-45deg);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-tr-ccw {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(45deg);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-br-cw {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-br-ccw {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-bl-cw {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-bl-ccw {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-tl-cw {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-45deg);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotate-in-2-tl-ccw {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(45deg);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes swirl-in-fwd {
  0% {
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-bck {
  0% {
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-top-fwd {
  0% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-top-bck {
  0% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-tr-fwd {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-tr-bck {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-right-fwd {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-right-bck {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-br-fwd {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-br-bck {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-bottom-fwd {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-bottom-bck {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-bl-fwd {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-bl-bck {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-left-fwd {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-left-bck {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-tl-fwd {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes swirl-in-tl-bck {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }
}

@keyframes flip-in-hor-bottom {
  0% {
    opacity: 0;
    transform: rotateX(80deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes flip-in-hor-top {
  0% {
    opacity: 0;
    transform: rotateX(-80deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes flip-in-ver-right {
  0% {
    opacity: 0;
    transform: rotateY(-80deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes flip-in-ver-left {
  0% {
    opacity: 0;
    transform: rotateY(80deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes flip-in-diag-1-tr {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, -80deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes flip-in-diag-1-bl {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 80deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes flip-in-diag-2-tl {
  0% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, 80deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes flip-in-diag-2-br {
  0% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, -80deg);
  }

  100% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes slit-in-vertical {
  0% {
    opacity: 0;
    transform: translateZ(-800px)rotateY(90deg);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotateY(87deg);
  }

  100% {
    transform: translateZ(0)rotateY(0);
  }
}

@keyframes slit-in-horizontal {
  0% {
    opacity: 0;
    transform: translateZ(-800px)rotateX(90deg);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotateX(87deg);
  }

  100% {
    transform: translateZ(0)rotateX(0);
  }
}

@keyframes slit-in-diagonal-1 {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateZ(-800px)rotate3d(1, 1, 0, 90deg);
  }

  54% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: translateZ(-160px)rotate3d(1, 1, 0, 87deg);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateZ(0)rotate3d(1, 1, 0, 0);
  }
}

@keyframes slit-in-diagonal-2 {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateZ(-800px)rotate3d(-1, 1, 0, -90deg);
  }

  54% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: translateZ(-160px)rotate3d(-1, 1, 0, -87deg);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateZ(0)rotate3d(-1, 1, 0, 0);
  }
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-tr {
  0% {
    opacity: 0;
    transform: translateY(-1000px)translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-br {
  0% {
    opacity: 0;
    transform: translateY(1000px)translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-bl {
  0% {
    opacity: 0;
    transform: translateY(1000px)translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-tl {
  0% {
    opacity: 0;
    transform: translateY(-1000px)translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }
}

@keyframes slide-in-fwd-center {
  0% {
    opacity: 0;
    transform: translateZ(-1400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes slide-in-fwd-top {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(-800px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes slide-in-fwd-tr {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(-800px)translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-fwd-right {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }
}

@keyframes slide-in-fwd-br {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(800px)translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-fwd-bottom {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(800px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes slide-in-fwd-bl {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(800px)translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-fwd-left {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }
}

@keyframes slide-in-fwd-tl {
  0% {
    opacity: 0;
    transform: translateZ(-1400px)translateY(-800px)translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-bck-center {
  0% {
    opacity: 0;
    transform: translateZ(600px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes slide-in-bck-top {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(-300px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes slide-in-bck-tr {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(-300px)translateX(400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-bck-right {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateX(400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }
}

@keyframes slide-in-bck-br {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(300px)translateX(400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-bck-bottom {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes slide-in-bck-bl {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(300px)translateX(-400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-bck-left {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateX(-400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }
}

@keyframes slide-in-bck-tl {
  0% {
    opacity: 0;
    transform: translateZ(700px)translateY(-300px)translateX(-400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }
}

@keyframes slide-in-blurred-top {
  0% {
    transform-origin: 50% 0;
    filter: blur(40px);
    opacity: 0;
    transform: translateY(-1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes slide-in-blurred-tr {
  0% {
    transform-origin: 0 0;
    filter: blur(40px);
    opacity: 0;
    transform: translate(1000px, -1000px)skew(-80deg, -10deg);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }
}

@keyframes slide-in-blurred-right {
  0% {
    transform-origin: 0;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(1000px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

@keyframes slide-in-blurred-br {
  0% {
    transform-origin: 0 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translate(1000px, 1000px)skew(80deg, 10deg);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    transform-origin: 50% 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateY(1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes slide-in-blurred-bl {
  0% {
    transform-origin: 100% 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translate(-1000px, 1000px)skew(-80deg, -10deg);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }
}

@keyframes slide-in-blurred-left {
  0% {
    transform-origin: 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(-1000px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

@keyframes slide-in-blurred-tl {
  0% {
    transform-origin: 100% 0;
    filter: blur(40px);
    opacity: 0;
    transform: translate(-1000px, -1000px)skew(80deg, 10deg);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: translateY(-600px)rotateX(-30deg)scale(0);
  }

  100% {
    transform-origin: 50% 1400px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }
}

@keyframes slide-in-elliptic-top-bck {
  0% {
    transform-origin: 50% 200%;
    opacity: 0;
    transform: translateY(-600px)rotateX(30deg)scale(6.5);
  }

  100% {
    transform-origin: 50% -500px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }
}

@keyframes slide-in-elliptic-right-fwd {
  0% {
    transform-origin: -100%;
    opacity: 0;
    transform: translateX(800px)rotateY(-30deg)scale(0);
  }

  100% {
    transform-origin: -1800px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }
}

@keyframes slide-in-elliptic-right-bck {
  0% {
    transform-origin: -100%;
    opacity: 0;
    transform: translateX(800px)rotateY(30deg)scale(6.5);
  }

  100% {
    transform-origin: 600px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }
}

@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: translateY(600px)rotateX(30deg)scale(0);
  }

  100% {
    transform-origin: 50% -1400px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }
}

@keyframes slide-in-elliptic-bottom-bck {
  0% {
    transform-origin: 50% -100%;
    opacity: 0;
    transform: translateY(600px)rotateX(-30deg)scale(6.5);
  }

  100% {
    transform-origin: 50% 500px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }
}

@keyframes slide-in-elliptic-left-fwd {
  0% {
    transform-origin: -100%;
    opacity: 0;
    transform: translateX(-800px)rotateY(30deg)scale(0);
  }

  100% {
    transform-origin: 1800px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }
}

@keyframes slide-in-elliptic-left-bck {
  0% {
    transform-origin: 200%;
    opacity: 0;
    transform: translateX(-800px)rotateY(-30deg)scale(6.5);
  }

  100% {
    transform-origin: -600px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }
}

@keyframes bounce-in-top {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(-500px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(-65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateX(600px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateX(68px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(32px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

@keyframes bounce-in-bottom {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(500px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes bounce-in-left {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateX(-600px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateX(-68px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

@keyframes bounce-in-fwd {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: scale(0);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(.7);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(.84);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(.95);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes bounce-in-bck {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: scale(7);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes roll-in-left {
  0% {
    opacity: 0;
    transform: translateX(-800px)rotate(-540deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

@keyframes roll-in-top {
  0% {
    opacity: 0;
    transform: translateY(-800px)rotate(-540deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }
}

@keyframes roll-in-right {
  0% {
    opacity: 0;
    transform: translateX(800px)rotate(540deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

@keyframes roll-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(800px)rotate(540deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }
}

@keyframes roll-in-blurred-left {
  0% {
    filter: blur(50px);
    opacity: 0;
    transform: translateX(-1000px)rotate(-720deg);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

@keyframes roll-in-blurred-top {
  0% {
    filter: blur(50px);
    opacity: 0;
    transform: translateY(-800px)rotate(-720deg);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: translateY(0)rotate(0);
  }
}

@keyframes roll-in-blurred-right {
  0% {
    filter: blur(50px);
    opacity: 0;
    transform: translateX(1000px)rotate(720deg);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

@keyframes roll-in-blurred-bottom {
  0% {
    filter: blur(50px);
    opacity: 0;
    transform: translateY(800px)rotate(720deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }
}

@keyframes tilt-in-top-1 {
  0% {
    opacity: 0;
    transform: rotateY(30deg)translateY(-300px)skewY(-30deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)translateY(0)skewY(0);
  }
}

@keyframes tilt-in-top-2 {
  0% {
    opacity: 0;
    transform: rotateY(-30deg)translateY(-300px)skewY(30deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)translateY(0)skewY(0);
  }
}

@keyframes tilt-in-tr {
  0% {
    opacity: 0;
    transform: rotateY(-35deg)rotateX(20deg)translate(250px, -250px)skew(-12deg, -15deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-right-1 {
  0% {
    opacity: 0;
    transform: rotateX(-30deg)translateX(300px)skewX(30deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0)translateX(0)skewX(0);
  }
}

@keyframes tilt-in-right-2 {
  0% {
    opacity: 0;
    transform: rotateX(30deg)translateX(300px)skewX(-30deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0)translateX(0)skewX(0);
  }
}

@keyframes tilt-in-br {
  0% {
    opacity: 0;
    transform: rotateY(-35deg)rotateX(-20deg)translate(250px, 250px)skew(12deg, 15deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-bottom-1 {
  0% {
    opacity: 0;
    transform: rotateY(30deg)translateY(300px)skewY(-30deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)translateY(0)skewY(0);
  }
}

@keyframes tilt-in-bottom-2 {
  0% {
    opacity: 0;
    transform: rotateY(-30deg)translateY(300px)skewY(30deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)translateY(0)skewY(0);
  }
}

@keyframes tilt-in-bl {
  0% {
    opacity: 0;
    transform: rotateY(35deg)rotateX(-20deg)translate(-250px, 250px)skew(-12deg, -15deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-left-1 {
  0% {
    opacity: 0;
    transform: rotateX(-30deg)translateX(-300px)skewX(-30deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0)translateX(0)skewX(0);
  }
}

@keyframes tilt-in-left-2 {
  0% {
    opacity: 0;
    transform: rotateX(30deg)translateX(-300px)skewX(30deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0)translateX(0)skewX(0);
  }
}

@keyframes tilt-in-tl {
  0% {
    opacity: 0;
    transform: rotateY(35deg)rotateX(20deg)translate(-250px, -250px)skew(12deg, 15deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-fwd-tr {
  0% {
    opacity: 0;
    transform: rotateY(20deg)rotateX(35deg)translate(300px, -300px)skew(-35deg, 10deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-fwd-br {
  0% {
    opacity: 0;
    transform: rotateY(20deg)rotateX(-35deg)translate(300px, 300px)skew(35deg, -10deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-fwd-bl {
  0% {
    opacity: 0;
    transform: rotateY(-20deg)rotateX(-35deg)translate(-300px, 300px)skew(-35deg, 10deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes tilt-in-fwd-tl {
  0% {
    opacity: 0;
    transform: rotateY(-20deg)rotateX(35deg)translate(-300px, -300px)skew(35deg, -10deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)rotateX(0)translate(0)skew(0deg);
  }
}

@keyframes swing-in-top-fwd {
  0% {
    transform-origin: top;
    opacity: 0;
    transform: rotateX(-100deg);
  }

  100% {
    transform-origin: top;
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swing-in-top-bck {
  0% {
    transform-origin: top;
    opacity: 0;
    transform: rotateX(70deg);
  }

  100% {
    transform-origin: top;
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swing-in-right-fwd {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotateY(-100deg);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes swing-in-right-bck {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotateY(70deg);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes swing-in-bottom-fwd {
  0% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swing-in-bottom-bck {
  0% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotateX(-70deg);
  }

  100% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swing-in-left-fwd {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: rotateY(100deg);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes swing-in-left-bck {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: rotateY(-70deg);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    opacity: 0;
    transform: translateZ(-80px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fade-in-bck {
  0% {
    opacity: 0;
    transform: translateZ(80px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-tr {
  0% {
    opacity: 0;
    transform: translateX(50px)translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-br {
  0% {
    opacity: 0;
    transform: translateX(50px)translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }
}

@keyframes fade-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bl {
  0% {
    opacity: 0;
    transform: translateX(-50px)translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-tl {
  0% {
    opacity: 0;
    transform: translateX(-50px)translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }
}

@keyframes puff-in-center {
  0% {
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-top {
  0% {
    transform-origin: 50% 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 50% 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-tr {
  0% {
    transform-origin: 100% 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 100% 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-right {
  0% {
    transform-origin: 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-br {
  0% {
    transform-origin: 100% 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 100% 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-bottom {
  0% {
    transform-origin: 50% 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 50% 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-bl {
  0% {
    transform-origin: 0 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 0 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-left {
  0% {
    transform-origin: 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-tl {
  0% {
    transform-origin: 0 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }

  100% {
    transform-origin: 0 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes puff-in-hor {
  0% {
    filter: blur(4px);
    opacity: 0;
    transform: scaleX(2);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes puff-in-ver {
  0% {
    filter: blur(4px);
    opacity: 0;
    transform: scaleY(2);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flicker-in-2 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  10.1% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  10.2% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  20% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  20.1% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff40;
  }

  20.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  30% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  30.1% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  30.5% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  30.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  45% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  45.1% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  50% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  57% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  57.1% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff4d;
  }

  60% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff4d;
  }

  60.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  65% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  65.1% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff4d, 0 0 100px #ffffff1a;
  }

  75% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff4d, 0 0 100px #ffffff1a;
  }

  75.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  77% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  77.1% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  85% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  85.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  86% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  86.1% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }
}

@keyframes scale-out-center {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-top {
  0% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-tr {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-right {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100%;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-br {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-bottom {
  0% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-bl {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-left {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-tl {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scale(0);
  }
}

@keyframes scale-out-horizontal {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 1;
    transform: scaleX(0);
  }
}

@keyframes scale-out-hor-left {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(0);
  }
}

@keyframes scale-out-hor-right {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleX(0);
  }
}

@keyframes scale-out-vertical {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    opacity: 1;
    transform: scaleY(0);
  }
}

@keyframes scale-out-ver-top {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleY(0);
  }
}

@keyframes scale-out-ver-bottom {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: scaleY(0);
  }
}

@keyframes rotate-out-center {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-top {
  0% {
    transform-origin: top;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: top;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-tr {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-right {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-br {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-bottom {
  0% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-bl {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-left {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-tl {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-360deg);
  }
}

@keyframes rotate-out-hor {
  0% {
    opacity: 1;
    transform: rotateX(360deg);
  }

  100% {
    opacity: 0;
    transform: rotateX(0);
  }
}

@keyframes rotate-out-ver {
  0% {
    opacity: 1;
    transform: rotateY(360deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(0);
  }
}

@keyframes rotate-out-diag-1 {
  0% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 360deg);
  }

  100% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@keyframes rotate-out-diag-2 {
  0% {
    opacity: 1;
    transform: rotate3d(-1, 1, 0, 360deg);
  }

  100% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, 0);
  }
}

@keyframes rotate-out-2-cw {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg);
  }
}

@keyframes rotate-out-2-ccw {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    transform: rotate(-45deg);
  }
}

@keyframes rotate-out-2-bck {
  0% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-180px)rotate(-45deg);
  }
}

@keyframes rotate-out-2-fwd {
  0% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(180px)rotate(45deg);
  }
}

@keyframes rotate-out-2-tr-cw {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(45deg);
  }
}

@keyframes rotate-out-2-tr-ccw {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

@keyframes rotate-out-2-br-cw {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(45deg);
  }
}

@keyframes rotate-out-2-br-ccw {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

@keyframes rotate-out-2-bl-cw {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }
}

@keyframes rotate-out-2-bl-ccw {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

@keyframes rotate-out-2-tl-cw {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(45deg);
  }
}

@keyframes rotate-out-2-tl-ccw {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

@keyframes swirl-out-bck {
  0% {
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-fwd {
  0% {
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-top-bck {
  0% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-top-fwd {
  0% {
    transform-origin: 50% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-tr-bck {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-tr-fwd {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-right-bck {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-right-fwd {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-br-bck {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-br-fwd {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-bottom-bck {
  0% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-bottom-fwd {
  0% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-bl-bck {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-bl-fwd {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-left-bck {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-left-fwd {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0;
    opacity: 0;
    transform: rotate(540deg)scale(5);
  }
}

@keyframes swirl-out-tl-bck {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(-540deg)scale(0);
  }
}

@keyframes swirl-out-tl-fwd {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: rotate(0)scale(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: rotate(720deg)scale(5);
  }
}

@keyframes flip-out-hor-top {
  0% {
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    opacity: 0;
    transform: rotateX(70deg);
  }
}

@keyframes flip-out-hor-bottom {
  0% {
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    opacity: 0;
    transform: rotateX(-70deg);
  }
}

@keyframes flip-out-ver-left {
  0% {
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    opacity: 0;
    transform: rotateY(-70deg);
  }
}

@keyframes flip-out-ver-right {
  0% {
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    opacity: 0;
    transform: rotateY(70deg);
  }
}

@keyframes flip-out-diag-1-tr {
  0% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 70deg);
  }
}

@keyframes flip-out-diag-1-bl {
  0% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, -70deg);
  }
}

@keyframes flip-out-diag-2-tl {
  0% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, -70deg);
  }
}

@keyframes flip-out-diag-2-br {
  0% {
    opacity: 1;
    transform: rotate3d(1, 1, 0, 0);
  }

  100% {
    opacity: 0;
    transform: rotate3d(-1, 1, 0, 70deg);
  }
}

@keyframes slit-out-vertical {
  0% {
    opacity: 1;
    transform: translateZ(0)rotateY(0);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotateY(87deg);
  }

  100% {
    opacity: 0;
    transform: translateZ(-800px)rotateY(90deg);
  }
}

@keyframes slit-out-horizontal {
  0% {
    opacity: 1;
    transform: translateZ(0)rotateX(0);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotateX(87deg);
  }

  100% {
    opacity: 0;
    transform: translateZ(-800px)rotateX(90deg);
  }
}

@keyframes slit-out-diagonal-1 {
  0% {
    opacity: 1;
    transform: translateZ(0)rotate3d(1, 1, 0, 0);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotate3d(1, 1, 0, 87deg);
  }

  100% {
    opacity: 0;
    transform: translateZ(-800px)rotate3d(1, 1, 0, 90deg);
  }
}

@keyframes slit-out-diagonal-2 {
  0% {
    opacity: 1;
    transform: translateZ(0)rotate3d(-1, 1, 0, 0);
  }

  54% {
    opacity: 1;
    transform: translateZ(-160px)rotate3d(-1, 1, 0, -87deg);
  }

  100% {
    opacity: 0;
    transform: translateZ(-800px)rotate3d(-1, 1, 0, -90deg);
  }
}

@keyframes slide-out-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px);
  }
}

@keyframes slide-out-tr {
  0% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px)translateX(1000px);
  }
}

@keyframes slide-out-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(1000px);
  }
}

@keyframes slide-out-br {
  0% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateY(1000px)translateX(1000px);
  }
}

@keyframes slide-out-bottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(1000px);
  }
}

@keyframes slide-out-bl {
  0% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateY(1000px)translateX(-1000px);
  }
}

@keyframes slide-out-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-1000px);
  }
}

@keyframes slide-out-tl {
  0% {
    opacity: 1;
    transform: translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px)translateX(-1000px);
  }
}

@keyframes slide-out-bck-center {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px);
  }
}

@keyframes slide-out-bck-top {
  0% {
    opacity: 1;
    transform: translateZ(1px)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(-1000px);
  }
}

@keyframes slide-out-bck-tr {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(-1000px)translateX(1000px);
  }
}

@keyframes slide-out-bck-right {
  0% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateX(1000px);
  }
}

@keyframes slide-out-bck-br {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(1000px)translateX(1000px);
  }
}

@keyframes slide-out-bck-bottom {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(1000px);
  }
}

@keyframes slide-out-bck-bl {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(1000px)translateX(-1000px);
  }
}

@keyframes slide-out-bck-left {
  0% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateX(-1000px);
  }
}

@keyframes slide-out-bck-tl {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-1100px)translateY(-1000px)translateX(-1000px);
  }
}

@keyframes slide-out-fwd-center {
  0% {
    opacity: 1;
    transform: translateZ(1px);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px);
  }
}

@keyframes slide-out-fwd-top {
  0% {
    opacity: 1;
    transform: translateZ(1px)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(-300px);
  }
}

@keyframes slide-out-fwd-tr {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(-300px)translateX(400px);
  }
}

@keyframes slide-out-fwd-right {
  0% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateX(400px);
  }
}

@keyframes slide-out-fwd-br {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(300px)translateX(400px);
  }
}

@keyframes slide-out-fwd-bottom {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(300px);
  }
}

@keyframes slide-out-fwd-bl {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(300px)translateX(-400px);
  }
}

@keyframes slide-out-fwd-left {
  0% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateX(-400px);
  }
}

@keyframes slide-out-fwd-tl {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0)translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(600px)translateY(-300px)translateX(-400px);
  }
}

@keyframes slide-out-blurred-top {
  0% {
    transform-origin: 50% 0;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 50% 0;
    filter: blur(40px);
    opacity: 0;
    transform: translateY(-1000px)scaleY(2)scaleX(.2);
  }
}

@keyframes slide-out-blurred-tr {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }

  100% {
    transform-origin: 0 0;
    filter: blur(40px);
    opacity: 0;
    transform: translate(1000px, -1000px)skew(-80deg, -10deg);
  }
}

@keyframes slide-out-blurred-right {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 0;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(1000px)scaleX(2)scaleY(.2);
  }
}

@keyframes slide-out-blurred-br {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }

  100% {
    transform-origin: 0 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translate(1000px, 1000px)skew(80deg, 10deg);
  }
}

@keyframes slide-out-blurred-bottom {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 50% 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateY(1000px)scaleY(2)scaleX(.2);
  }
}

@keyframes slide-out-blurred-bl {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }

  100% {
    transform-origin: 100% 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translate(-1000px, 1000px)skew(-80deg, -10deg);
  }
}

@keyframes slide-out-blurred-left {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(-1000px)scaleX(2)scaleY(.2);
  }
}

@keyframes slide-out-blurred-tl {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translate(0)skew(0deg);
  }

  100% {
    transform-origin: 100% 0;
    filter: blur(40px);
    opacity: 0;
    transform: translate(-1000px, -1000px)skew(80deg, 10deg);
  }
}

@keyframes slide-out-elliptic-top-bck {
  0% {
    transform-origin: 50% 1400px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: translateY(-600px)rotateX(-30deg)scale(0);
  }
}

@keyframes slide-out-elliptic-top-fwd {
  0% {
    transform-origin: 50% -500px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }

  100% {
    transform-origin: 50% 200%;
    opacity: 0;
    transform: translateY(-600px)rotateX(20deg)scale(6);
  }
}

@keyframes slide-out-elliptic-right-bck {
  0% {
    transform-origin: -1800px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }

  100% {
    transform-origin: -100%;
    opacity: 1;
    transform: translateX(1000px)rotateY(-30deg)scale(0);
  }
}

@keyframes slide-out-elliptic-right-fwd {
  0% {
    transform-origin: 600px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }

  100% {
    transform-origin: -100%;
    opacity: 0;
    transform: translateX(1000px)rotateY(20deg)scale(6);
  }
}

@keyframes slide-out-elliptic-bottom-bck {
  0% {
    transform-origin: 50% -1400px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 1;
    transform: translateY(600px)rotateX(30deg)scale(0);
  }
}

@keyframes slide-out-elliptic-bottom-fwd {
  0% {
    transform-origin: 50% 500px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }

  100% {
    transform-origin: 50% -100%;
    opacity: 0;
    transform: translateY(600px)rotateX(-20deg)scale(6);
  }
}

@keyframes slide-out-elliptic-left-bck {
  0% {
    transform-origin: 2000px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }

  100% {
    transform-origin: -100%;
    opacity: 1;
    transform: translateX(-1000px)rotateY(30deg)scale(0);
  }
}

@keyframes slide-out-elliptic-left-fwd {
  0% {
    transform-origin: -500px;
    opacity: 1;
    transform: translateX(0)rotateY(0)scale(1);
  }

  100% {
    transform-origin: 200%;
    opacity: 0;
    transform: translateX(-1000px)rotateY(-20deg)scale(6);
  }
}

@keyframes bounce-out-top {
  0% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateY(-30px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateY(-38px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateY(-75px);
  }

  70% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-800px);
  }
}

@keyframes bounce-out-right {
  0% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateX(30px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateX(38px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateX(80px);
  }

  65% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(1000px);
  }
}

@keyframes bounce-out-bottom {
  0% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateY(30px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateY(38px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateY(75px);
  }

  70% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(800px);
  }
}

@keyframes bounce-out-left {
  0% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateX(-30px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateX(-38px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateX(-80px);
  }

  70% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-1000px);
  }
}

@keyframes bounce-out-bck {
  0% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateZ(-100px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateZ(-110px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateZ(-200px);
  }

  70% {
    animation-timing-function: ease-out;
    transform: translateZ(0)scale(1);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateZ(-900px)scale(0);
  }
}

@keyframes bounce-out-fwd {
  0% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  5% {
    animation-timing-function: ease-in;
    transform: translateZ(90px);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  25% {
    animation-timing-function: ease-in;
    transform: translateZ(95px);
  }

  38% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  52% {
    animation-timing-function: ease-in;
    transform: translateZ(150px);
  }

  70% {
    animation-timing-function: ease-out;
    transform: translateZ(0);
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateZ(500px);
  }
}

@keyframes roll-out-left {
  0% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-1000px)rotate(-540deg);
  }
}

@keyframes roll-out-top {
  0% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-800px)rotate(-540deg);
  }
}

@keyframes roll-out-right {
  0% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(1000px)rotate(540deg);
  }
}

@keyframes roll-out-bottom {
  0% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateY(800px)rotate(540deg);
  }
}

@keyframes roll-out-blurred-left {
  0% {
    filter: blur();
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    filter: blur(50px);
    opacity: 0;
    transform: translateX(-1000px)rotate(-720deg);
  }
}

@keyframes roll-out-blurred-top {
  0% {
    filter: blur();
    opacity: 1;
    transform: translateY(0)rotate(0);
  }

  100% {
    filter: blur(50px);
    opacity: 0;
    transform: translateY(-800px)rotate(-720deg);
  }
}

@keyframes roll-out-blurred-right {
  0% {
    filter: blur();
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    filter: blur(50px);
    opacity: 0;
    transform: translateX(1000px)rotate(720deg);
  }
}

@keyframes roll-out-blurred-bottom {
  0% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }

  100% {
    filter: blur(50px);
    opacity: 0;
    transform: translateY(800px)rotate(720deg);
  }
}

@keyframes swing-out-top-bck {
  0% {
    transform-origin: top;
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    transform-origin: top;
    opacity: 0;
    transform: rotateX(-100deg);
  }
}

@keyframes swing-out-top-fwd {
  0% {
    transform-origin: top;
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    transform-origin: top;
    opacity: 0;
    transform: rotateX(70deg);
  }
}

@keyframes swing-out-right-bck {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotateY(-100deg);
  }
}

@keyframes swing-out-right-fwd {
  0% {
    transform-origin: 100%;
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: rotateY(70deg);
  }
}

@keyframes swing-out-bottom-bck {
  0% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotateX(100deg);
  }
}

@keyframes swing-out-bottom-fwd {
  0% {
    transform-origin: bottom;
    opacity: 1;
    transform: rotateX(0);
  }

  100% {
    transform-origin: bottom;
    opacity: 0;
    transform: rotateX(-70deg);
  }
}

@keyframes swing-out-left-bck {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 0;
    opacity: 0;
    transform: rotateY(100deg);
  }
}

@keyframes swing-out-left-fwd {
  0% {
    transform-origin: 0;
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    transform-origin: 0;
    opacity: 0;
    transform: rotateY(-70deg);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out-bck {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(-80px);
  }
}

@keyframes fade-out-fwd {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    transform: translateZ(80px);
  }
}

@keyframes fade-out-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes fade-out-tr {
  0% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px)translateY(-50px);
  }
}

@keyframes fade-out-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes fade-out-br {
  0% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px)translateY(50px);
  }
}

@keyframes fade-out-bottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

@keyframes fade-out-bl {
  0% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50px)translateY(50px);
  }
}

@keyframes fade-out-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes fade-out-tl {
  0% {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50px)translateY(-50px);
  }
}

@keyframes puff-out-center {
  0% {
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-top {
  0% {
    transform-origin: 50% 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-tr {
  0% {
    transform-origin: 100% 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-right {
  0% {
    transform-origin: 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-br {
  0% {
    transform-origin: 100% 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 100% 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-bottom {
  0% {
    transform-origin: 50% 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-bl {
  0% {
    transform-origin: 0 100%;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 100%;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-left {
  0% {
    transform-origin: 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-tl {
  0% {
    transform-origin: 0 0;
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform-origin: 0 0;
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes puff-out-hor {
  0% {
    filter: blur();
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    filter: blur(4px);
    opacity: 0;
    transform: scaleX(2);
  }
}

@keyframes puff-out-ver {
  0% {
    filter: blur();
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    filter: blur(4px);
    opacity: 0;
    transform: scaleY(2);
  }
}

@keyframes flicker-out-1 {
  0% {
    opacity: 1;
  }

  13.9% {
    opacity: 1;
  }

  14% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  15% {
    opacity: 1;
  }

  22.9% {
    opacity: 1;
  }

  23% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  25% {
    opacity: 1;
  }

  34.9% {
    opacity: 1;
  }

  35% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  40% {
    opacity: 1;
  }

  42.9% {
    opacity: 1;
  }

  43% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  54.9% {
    opacity: 1;
  }

  55% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  69.5% {
    opacity: 1;
  }

  69.9% {
    opacity: 1;
  }

  70% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  79.9% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  90% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  100% {
    opacity: 0;
  }
}

@keyframes flicker-out-2 {
  0% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  13.9% {
    opacity: 1;
    box-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  14% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  15% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  22.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  23% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  25% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  34.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  35% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  40% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  42.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  43% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  45% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  50% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  54.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  69.5% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  69.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  70% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  79.9% {
    opacity: 1;
    box-shadow: 0 0 30px #ffffff40;
  }

  80% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  90% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  100% {
    opacity: 0;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -.5em;
    opacity: 0;
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-700px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-700px)translateY(-500px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-700px)translateY(500px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: .6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(400px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(400px)translateY(-300px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(400px)translateY(300px);
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }
}

@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    letter-spacing: -.5em;
    opacity: 0;
  }
}

@keyframes tracking-out-contract-bck {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-500px);
  }
}

@keyframes tracking-out-contract-bck-top {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-500px)translateY(-300px);
  }
}

@keyframes tracking-out-contract-bck-bottom {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    letter-spacing: -.5em;
    opacity: 0;
    transform: translateZ(-500px)translateY(300px);
  }
}

@keyframes tracking-out-expand {
  0% {
    opacity: 1;
  }

  60% {
    opacity: .8;
  }

  100% {
    letter-spacing: 1em;
    opacity: 0;
  }
}

@keyframes tracking-out-expand-fwd {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  60% {
    opacity: .8;
  }

  100% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(300px);
  }
}

@keyframes tracking-out-expand-fwd-top {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  60% {
    opacity: .8;
  }

  100% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(300px)translateY(-200px);
  }
}

@keyframes tracking-out-expand-fwd-bottom {
  0% {
    opacity: 1;
    transform: translateZ(0)translateY(0);
  }

  60% {
    opacity: .8;
  }

  100% {
    letter-spacing: 1em;
    opacity: 0;
    transform: translateZ(300px)translateY(200px);
  }
}

@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    filter: blur();
    opacity: 1;
  }
}

@keyframes focus-in-expand {
  0% {
    letter-spacing: -.5em;
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    filter: blur();
    opacity: 1;
  }
}

@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -.5em;
    filter: blur(12px);
    opacity: 0;
    transform: translateZ(-800px);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    filter: blur();
    opacity: 1;
  }
}

@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    filter: blur(12px);
    opacity: 0;
    transform: translateZ(300px);
  }

  100% {
    filter: blur();
    opacity: 1;
    transform: translateZ(12px);
  }
}

@keyframes text-blur-out {
  0% {
    filter: blur(.01px);
  }

  100% {
    filter: blur(12px) opacity(0);
  }
}

@keyframes blur-out-contract {
  0% {
    filter: blur(.01px);
  }

  100% {
    letter-spacing: -.5em;
    filter: blur(12px) opacity(0);
  }
}

@keyframes blur-out-contract-bck {
  0% {
    filter: blur(.01px);
    transform: translateZ(0);
  }

  100% {
    letter-spacing: -.5em;
    filter: blur(12px) opacity(0);
    transform: translateZ(-500px);
  }
}

@keyframes blur-out-expand {
  0% {
    filter: blur(.01px);
  }

  100% {
    letter-spacing: 1em;
    filter: blur(12px) opacity(0);
  }
}

@keyframes blur-out-expand-fwd {
  0% {
    filter: blur(.01px);
    transform: translateZ(0);
  }

  100% {
    letter-spacing: 1em;
    filter: blur(12px) opacity(0);
    transform: translateZ(300px);
  }
}

@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    text-shadow: none;
  }

  10.1% {
    opacity: 1;
    text-shadow: none;
  }

  10.2% {
    opacity: 0;
    text-shadow: none;
  }

  20% {
    opacity: 0;
    text-shadow: none;
  }

  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff40;
  }

  20.6% {
    opacity: 0;
    text-shadow: none;
  }

  30% {
    opacity: 0;
    text-shadow: none;
  }

  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  30.6% {
    opacity: 0;
    text-shadow: none;
  }

  45% {
    opacity: 0;
    text-shadow: none;
  }

  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  50% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55.1% {
    opacity: 0;
    text-shadow: none;
  }

  57% {
    opacity: 0;
    text-shadow: none;
  }

  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  60% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  60.1% {
    opacity: 0;
    text-shadow: none;
  }

  65% {
    opacity: 0;
    text-shadow: none;
  }

  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  75% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  75.1% {
    opacity: 0;
    text-shadow: none;
  }

  77% {
    opacity: 0;
    text-shadow: none;
  }

  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  85% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  85.1% {
    opacity: 0;
    text-shadow: none;
  }

  86% {
    opacity: 0;
    text-shadow: none;
  }

  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }
}

@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px #fff9, 0 0 60px #ffffff73, 0 0 110px #ffffff40, 0 0 100px #ffffff1a;
  }

  14% {
    opacity: 0;
    text-shadow: none;
  }

  14.9% {
    opacity: 0;
    text-shadow: none;
  }

  15% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #fff6, 0 0 110px #fff3, 0 0 100px #ffffff1a;
  }

  23% {
    opacity: 0;
    text-shadow: none;
  }

  24.9% {
    opacity: 0;
    text-shadow: none;
  }

  25% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59, 0 0 100px #ffffff1a;
  }

  35% {
    opacity: 0;
    text-shadow: none;
  }

  39.9% {
    opacity: 0;
    text-shadow: none;
  }

  40% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff8c, 0 0 60px #ffffff59;
  }

  43% {
    opacity: 0;
    text-shadow: none;
  }

  44.9% {
    opacity: 0;
    text-shadow: none;
  }

  45% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  50% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  55% {
    opacity: 0;
    text-shadow: none;
  }

  69.4% {
    opacity: 0;
    text-shadow: none;
  }

  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff73, 0 0 60px #ffffff40;
  }

  70% {
    opacity: 0;
    text-shadow: none;
  }

  79.4% {
    opacity: 0;
    text-shadow: none;
  }

  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px #ffffff40;
  }

  80% {
    opacity: 0;
    text-shadow: none;
  }

  89.8% {
    opacity: 0;
    text-shadow: none;
  }

  89.9% {
    opacity: 1;
    text-shadow: none;
  }

  90% {
    opacity: 0;
    text-shadow: none;
  }

  100% {
    opacity: 0;
  }
}

@keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 0 0 18px #00000059;
  }
}

@keyframes text-shadow-drop-top {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 0 -6px 18px #00000059;
  }
}

@keyframes text-shadow-drop-tr {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 6px -6px 18px #00000059;
  }
}

@keyframes text-shadow-drop-right {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 6px 0 18px #00000059;
  }
}

@keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 6px 6px 18px #00000059;
  }
}

@keyframes text-shadow-drop-bottom {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: 0 6px 18px #00000059;
  }
}

@keyframes text-shadow-drop-bl {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: -6px 6px 18px #00000059;
  }
}

@keyframes text-shadow-drop-left {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: -6px 0 18px #00000059;
  }
}

@keyframes text-shadow-drop-tl {
  0% {
    text-shadow: 0 0 #0000;
  }

  100% {
    text-shadow: -6px -6px 18px #00000059;
  }
}

@keyframes text-shadow-pop-top {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateY(0);
  }

  100% {
    text-shadow: 0 -1px #555, 0 -2px #555, 0 -3px #555, 0 -4px #555, 0 -5px #555, 0 -6px #555, 0 -7px #555, 0 -8px #555;
    transform: translateY(8px);
  }
}

@keyframes text-shadow-pop-tr {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0)translateY(0);
  }

  100% {
    text-shadow: 1px -1px #555, 2px -2px #555, 3px -3px #555, 4px -4px #555, 5px -5px #555, 6px -6px #555, 7px -7px #555, 8px -8px #555;
    transform: translateX(-8px)translateY(8px);
  }
}

@keyframes text-shadow-pop-right {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0);
  }

  100% {
    text-shadow: 1px 0 #555, 2px 0 #555, 3px 0 #555, 4px 0 #555, 5px 0 #555, 6px 0 #555, 7px 0 #555, 8px 0 #555;
    transform: translateX(-8px);
  }
}

@keyframes text-shadow-pop-br {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0)translateY(0);
  }

  100% {
    text-shadow: 1px 1px #555, 2px 2px #555, 3px 3px #555, 4px 4px #555, 5px 5px #555, 6px 6px #555, 7px 7px #555, 8px 8px #555;
    transform: translateX(-8px)translateY(-8px);
  }
}

@keyframes text-shadow-pop-bottom {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateY(0);
  }

  100% {
    text-shadow: 0 1px #555, 0 2px #555, 0 3px #555, 0 4px #555, 0 5px #555, 0 6px #555, 0 7px #555, 0 8px #555;
    transform: translateY(-8px);
  }
}

@keyframes text-shadow-pop-bl {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0)translateY(0);
  }

  100% {
    text-shadow: -1px 1px #555, -2px 2px #555, -3px 3px #555, -4px 4px #555, -5px 5px #555, -6px 6px #555, -7px 7px #555, -8px 8px #555;
    transform: translateX(8px)translateY(-8px);
  }
}

@keyframes text-shadow-pop-left {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0);
  }

  100% {
    text-shadow: -1px 0 #555, -2px 0 #555, -3px 0 #555, -4px 0 #555, -5px 0 #555, -6px 0 #555, -7px 0 #555, -8px 0 #555;
    transform: translateX(8px);
  }
}

@keyframes text-shadow-pop-tl {
  0% {
    text-shadow: 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555, 0 0 #555;
    transform: translateX(0)translateY(0);
  }

  100% {
    text-shadow: -1px -1px #555, -2px -2px #555, -3px -3px #555, -4px -4px #555, -5px -5px #555, -6px -6px #555, -7px -7px #555, -8px -8px #555;
    transform: translateX(8px)translateY(8px);
  }
}

@keyframes text-pop-up-top {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(-50px);
  }
}

@keyframes text-pop-up-tr {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0)translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(-50px)translateX(50px);
  }
}

@keyframes text-pop-up-right {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateX(50px);
  }
}

@keyframes text-pop-up-br {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0)translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(50px)translateX(50px);
  }
}

@keyframes text-pop-up-bottom {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(50px);
  }
}

@keyframes text-pop-up-bl {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0)translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(50px)translateX(-50px);
  }
}

@keyframes text-pop-up-left {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateX(-50px);
  }
}

@keyframes text-pop-up-tl {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0)translateX(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(-50px)translateX(-50px);
  }
}

@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes vibrate-2 {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(2px, -2px);
  }

  40% {
    transform: translate(2px, 2px);
  }

  60% {
    transform: translate(-2px, 2px);
  }

  80% {
    transform: translate(-2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes vibrate-3 {
  0% {
    transform: translate(0);
  }

  10% {
    transform: translate(-2px, -2px);
  }

  20% {
    transform: translate(2px, -2px);
  }

  30% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(2px, 2px);
  }

  50% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, -2px);
  }

  70% {
    transform: translate(-2px, 2px);
  }

  80% {
    transform: translate(-2px, -2px);
  }

  90% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes flicker-1 {
  0%, 100% {
    opacity: 1;
  }

  41.99% {
    opacity: 1;
  }

  42% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  43.01% {
    opacity: 1;
  }

  47.99% {
    opacity: 1;
  }

  48% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  49.01% {
    opacity: 1;
  }
}

@keyframes flicker-2 {
  0%, 100% {
    opacity: 1;
  }

  41.99% {
    opacity: 1;
  }

  42% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  43.01% {
    opacity: 1;
  }

  45.99% {
    opacity: 1;
  }

  46% {
    opacity: 0;
  }

  46.9% {
    opacity: 0;
  }

  46.91% {
    opacity: 1;
  }

  51.99% {
    opacity: 1;
  }

  52% {
    opacity: 0;
  }

  52.8% {
    opacity: 0;
  }

  52.81% {
    opacity: 1;
  }
}

@keyframes flicker-3 {
  0%, 100% {
    opacity: 1;
  }

  32.98% {
    opacity: 1;
  }

  33% {
    opacity: 0;
  }

  34% {
    opacity: 0;
  }

  34.02% {
    opacity: 1;
  }

  34.98% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  35.9% {
    opacity: 0;
  }

  35.92% {
    opacity: 1;
  }

  38.98% {
    opacity: 1;
  }

  39% {
    opacity: 0;
  }

  39.8% {
    opacity: 0;
  }

  39.82% {
    opacity: 1;
  }

  83.98% {
    opacity: 1;
  }

  84% {
    opacity: 0;
  }

  84.9% {
    opacity: 0;
  }

  84.92% {
    opacity: 1;
  }
}

@keyframes flicker-4 {
  0%, 100% {
    opacity: 1;
  }

  31.98% {
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  32.8% {
    opacity: 0;
  }

  32.82% {
    opacity: 1;
  }

  34.98% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  35.7% {
    opacity: 0;
  }

  35.72% {
    opacity: 1;
  }

  36.98% {
    opacity: 1;
  }

  37% {
    opacity: 0;
  }

  37.6% {
    opacity: 0;
  }

  37.62% {
    opacity: 1;
  }

  67.98% {
    opacity: 1;
  }

  68% {
    opacity: 0;
  }

  68.4% {
    opacity: 0;
  }

  68.42% {
    opacity: 1;
  }

  95.98% {
    opacity: 1;
  }

  96% {
    opacity: 0;
  }

  96.7% {
    opacity: 0;
  }

  96.72% {
    opacity: 1;
  }

  98.98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  99.6% {
    opacity: 0;
  }

  99.62% {
    opacity: 1;
  }
}

@keyframes shake-horizontal {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70% {
    transform: translateX(-10px);
  }

  20%, 40%, 60% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(-8px);
  }
}

@keyframes shake-vertical {
  0%, 100% {
    transform: translateY(0);
  }

  10%, 30%, 50%, 70% {
    transform: translateY(-8px);
  }

  20%, 40%, 60% {
    transform: translateY(8px);
  }

  80% {
    transform: translateY(6.4px);
  }

  90% {
    transform: translateY(-6.4px);
  }
}

@keyframes shake-lr {
  0%, 100% {
    transform-origin: 50%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(8deg);
  }

  20%, 40%, 60% {
    transform: rotate(-10deg);
  }

  30%, 50%, 70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}

@keyframes shake-top {
  0%, 100% {
    transform-origin: 50% 0;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-tr {
  0%, 100% {
    transform-origin: 100% 0;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-right {
  0%, 100% {
    transform-origin: 100%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-br {
  0%, 100% {
    transform-origin: 100% 100%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-bottom {
  0%, 100% {
    transform-origin: 50% 100%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-bl {
  0%, 100% {
    transform-origin: 0 100%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-left {
  0%, 100% {
    transform-origin: 0;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes shake-tl {
  0%, 100% {
    transform-origin: 0 0;
    transform: rotate(0);
  }

  10% {
    transform: rotate(2deg);
  }

  20%, 40%, 60% {
    transform: rotate(-4deg);
  }

  30%, 50%, 70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, .75, 1);
  }

  50% {
    transform: scale3d(.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, .95, 1);
  }

  75% {
    transform: scale3d(.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-diagonal-1 {
  0% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }

  30% {
    -webkit-transform: skew(25deg 25deg);
    transform: skew(25deg 25deg);
  }

  40% {
    transform: skew(-15deg, -15deg);
  }

  50% {
    transform: skew(15deg, 15deg);
  }

  65% {
    transform: skew(-5deg, -5deg);
  }

  75% {
    transform: skew(5deg, 5deg);
  }

  100% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }
}

@keyframes jello-diagonal-2 {
  0% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }

  30% {
    -webkit-transform: skew(-25deg -25deg);
    transform: skew(-25deg -25deg);
  }

  40% {
    transform: skew(15deg, 15deg);
  }

  50% {
    transform: skew(-15deg, -15deg);
  }

  65% {
    transform: skew(5deg, 5deg);
  }

  75% {
    transform: skew(-5deg, -5deg);
  }

  100% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }
}

@keyframes wobble-hor-bottom {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0);
  }

  15% {
    transform: translateX(-30px)rotate(-6deg);
  }

  30% {
    transform: translateX(15px)rotate(6deg);
  }

  45% {
    transform: translateX(-15px)rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px)rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px)rotate(-1.2deg);
  }
}

@keyframes wobble-hor-top {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0);
  }

  15% {
    transform: translateX(-30px)rotate(6deg);
  }

  30% {
    transform: translateX(15px)rotate(-6deg);
  }

  45% {
    transform: translateX(-15px)rotate(3.6deg);
  }

  60% {
    transform: translateX(9px)rotate(-2.4deg);
  }

  75% {
    transform: translateX(-6px)rotate(1.2deg);
  }
}

@keyframes wobble-ver-left {
  0%, 100% {
    transform-origin: 50%;
    transform: translateY(0)rotate(0);
  }

  15% {
    transform: translateY(-30px)rotate(-6deg);
  }

  30% {
    transform: translateY(15px)rotate(6deg);
  }

  45% {
    transform: translateY(-15px)rotate(-3.6deg);
  }

  60% {
    transform: translateY(9px)rotate(2.4deg);
  }

  75% {
    transform: translateY(-6px)rotate(-1.2deg);
  }
}

@keyframes wobble-ver-right {
  0%, 100% {
    transform-origin: 50%;
    transform: translateY(0)rotate(0);
  }

  15% {
    transform: translateY(-30px)rotate(6deg);
  }

  30% {
    transform: translateY(15px)rotate(-6deg);
  }

  45% {
    transform: translateY(-15px)rotate(3.6deg);
  }

  60% {
    transform: translateY(9px)rotate(-2.4deg);
  }

  75% {
    transform: translateY(-6px)rotate(1.2deg);
  }
}

@keyframes bounce-top {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%, 55%, 75%, 87% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes bounce-bottom {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translateY(45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(4px);
  }

  25%, 55%, 75%, 87% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes bounce-left {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translateX(-48px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateX(-26px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateX(-13px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateX(-6.5px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateX(-4px);
  }

  25%, 55%, 75%, 87%, 98% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

@keyframes bounce-right {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translateX(48px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateX(26px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateX(13px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateX(6.5px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateX(4px);
  }

  25%, 55%, 75%, 87%, 98% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

@keyframes heartbeat {
  from {
    transform-origin: center;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ping {
  0% {
    opacity: .8;
    transform: scale(.2);
  }

  80% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    transform: scale(2.2);
  }
}

@keyframes blink-1 {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes blink-2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes kenburns-top {
  0% {
    transform-origin: 50% 16%;
    transform: scale(1)translateY(0);
  }

  100% {
    transform-origin: top;
    transform: scale(1.25)translateY(-15px);
  }
}

@keyframes kenburns-top-right {
  0% {
    transform-origin: 84% 16%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 100% 0;
    transform: scale(1.25)translate(20px, -15px);
  }
}

@keyframes kenburns-right {
  0% {
    transform-origin: 84%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 100%;
    transform: scale(1.25)translateX(20px);
  }
}

@keyframes kenburns-bottom-right {
  0% {
    transform-origin: 84% 84%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scale(1.25)translate(20px, 15px);
  }
}

@keyframes kenburns-bottom {
  0% {
    transform-origin: 50% 84%;
    transform: scale(1)translateY(0);
  }

  100% {
    transform-origin: bottom;
    transform: scale(1.25)translateY(15px);
  }
}

@keyframes kenburns-bottom-left {
  0% {
    transform-origin: 16% 84%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 0 100%;
    transform: scale(1.25)translate(-20px, 15px);
  }
}

@keyframes kenburns-left {
  0% {
    transform-origin: 16%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 0;
    transform: scale(1.25)translate(-20px, 15px);
  }
}

@keyframes kenburns-top-left {
  0% {
    transform-origin: 16% 16%;
    transform: scale(1)translate(0);
  }

  100% {
    transform-origin: 0 0;
    transform: scale(1.25)translate(-20px, -15px);
  }
}

@keyframes bg-pan-left {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

@keyframes bg-pan-right {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0;
  }
}

@keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: 50% 100%;
  }
}

@keyframes bg-pan-tr {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes bg-pan-br {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

@keyframes bg-pan-bl {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 100%;
  }
}

@keyframes bg-pan-tl {
  0% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes color-change-2x {
  0% {
    background: #19dcea;
  }

  100% {
    background: #b22cff;
  }
}

@keyframes color-change-3x {
  0% {
    background: #19dcea;
  }

  50% {
    background: #b22cff;
  }

  100% {
    background: #ea2222;
  }
}

@keyframes color-change-4x {
  0% {
    background: #19dcea;
  }

  33.3333% {
    background: #b22cff;
  }

  66.666% {
    background: #ea2222;
  }

  100% {
    background: #f5be10;
  }
}

@keyframes color-change-5x {
  0% {
    background: #19dcea;
  }

  25% {
    background: #b22cff;
  }

  50% {
    background: #ea2222;
  }

  75% {
    background: #f5be10;
  }

  100% {
    background: #3bd80d;
  }
}

.scale-up-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-center;
}

.scale-up-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-top;
}

.scale-up-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-tr;
}

.scale-up-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-right;
}

.scale-up-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-br;
}

.scale-up-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-bottom;
}

.scale-up-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-bl;
}

.scale-up-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-left;
}

.scale-up-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-tl;
}

.scale-up-hor-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-hor-center;
}

.scale-up-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-hor-left;
}

.scale-up-hor-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-hor-right;
}

.scale-up-ver-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-ver-center;
}

.scale-up-ver-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-ver-top;
}

.scale-up-ver-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-up-ver-bottom;
}

.scale-down-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-center;
}

.scale-down-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-top;
}

.scale-down-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-tr;
}

.scale-down-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-right;
}

.scale-down-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-br;
}

.scale-down-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-bottom;
}

.scale-down-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-bl;
}

.scale-down-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-left;
}

.scale-down-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-tl;
}

.scale-down-hor-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-hor-center;
}

.scale-down-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-hor-left;
}

.scale-down-hor-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-hor-right;
}

.scale-down-ver-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-ver-center;
}

.scale-down-ver-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-ver-top;
}

.scale-down-ver-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-down-ver-bottom;
}

.rotate-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-center;
}

.rotate-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-top;
}

.rotate-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-tr;
}

.rotate-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-right;
}

.rotate-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-br;
}

.rotate-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-bottom;
}

.rotate-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-bl;
}

.rotate-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-left;
}

.rotate-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-tl;
}

.rotate-hor-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-hor-center;
}

.rotate-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-hor-top;
}

.rotate-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-hor-bottom;
}

.rotate-vert-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-vert-center;
}

.rotate-vert-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-vert-left;
}

.rotate-vert-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-vert-right;
}

.rotate-diagonal-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-1;
}

.rotate-diagonal-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-2;
}

.rotate-diagonal-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-tr;
}

.rotate-diagonal-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-br;
}

.rotate-diagonal-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-bl;
}

.rotate-diagonal-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-diagonal-tl;
}

.rotate-scale-up {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-up;
}

.rotate-scale-down {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-down;
}

.rotate-scale-up-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-up-hor;
}

.rotate-scale-down-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-down-hor;
}

.rotate-scale-up-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-up-ver;
}

.rotate-scale-down-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-down-ver;
}

.rotate-scale-up-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-up-diag-1;
}

.rotate-scale-down-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-down-diag-1;
}

.rotate-scale-up-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-up-diag-2;
}

.rotate-scale-down-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-scale-down-diag-2;
}

.rotate-90-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-cw;
}

.rotate-90-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-ccw;
}

.rotate-90-top-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-top-cw;
}

.rotate-90-top-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-top-ccw;
}

.rotate-90-tr-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-tr-cw;
}

.rotate-90-tr-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-tr-ccw;
}

.rotate-90-right-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-right-cw;
}

.rotate-90-right-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-right-ccw;
}

.rotate-90-br-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-br-cw;
}

.rotate-90-br-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-br-ccw;
}

.rotate-90-bottom-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-bottom-cw;
}

.rotate-90-bottom-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-bottom-ccw;
}

.rotate-90-bl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-bl-cw;
}

.rotate-90-bl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-bl-ccw;
}

.rotate-90-left-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-left-cw;
}

.rotate-90-left-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-left-ccw;
}

.rotate-90-tl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-tl-cw;
}

.rotate-90-tl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-tl-ccw;
}

.rotate-90-horizontal-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-horizontal-fwd;
}

.rotate-90-horizontal-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-horizontal-bck;
}

.rotate-90-vertical-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-vertical-fwd;
}

.rotate-90-vertical-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-90-vertical-bck;
}

.flip-horizontal-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-horizontal-bottom;
}

.flip-horizontal-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-horizontal-top;
}

.flip-horizontal-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-horizontal-bck;
}

.flip-horizontal-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-horizontal-fwd;
}

.flip-vertical-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-vertical-right;
}

.flip-vertical-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-vertical-left;
}

.flip-vertical-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-vertical-bck;
}

.flip-vertical-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-vertical-fwd;
}

.flip-diagonal-1-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-1-tr;
}

.flip-diagonal-1-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-1-bl;
}

.flip-diagonal-1-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-1-bck;
}

.flip-diagonal-1-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-1-fwd;
}

.flip-diagonal-2-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-2-br;
}

.flip-diagonal-2-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-2-tl;
}

.flip-diagonal-2-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-2-bck;
}

.flip-diagonal-2-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-diagonal-2-fwd;
}

.flip-2-hor-top-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-top-1;
}

.flip-2-hor-top-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-top-2;
}

.flip-2-hor-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-top-bck;
}

.flip-2-hor-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-top-fwd;
}

.flip-2-ver-right-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-right-1;
}

.flip-2-ver-right-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-right-2;
}

.flip-2-ver-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-right-bck;
}

.flip-2-ver-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-right-fwd;
}

.flip-2-hor-bottom-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-bottom-1;
}

.flip-2-hor-bottom-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-bottom-2;
}

.flip-2-hor-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-bottom-bck;
}

.flip-2-hor-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-hor-bottom-fwd;
}

.flip-2-ver-left-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-left-1;
}

.flip-2-ver-left-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-left-2;
}

.flip-2-ver-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-left-bck;
}

.flip-2-ver-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-2-ver-left-fwd;
}

.flip-scale-up-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-up-hor;
}

.flip-scale-down-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-down-hor;
}

.flip-scale-up-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-up-ver;
}

.flip-scale-down-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-down-ver;
}

.flip-scale-up-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-up-diag-1;
}

.flip-scale-down-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-down-diag-1;
}

.flip-scale-up-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-up-diag-2;
}

.flip-scale-down-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-down-diag-2;
}

.flip-scale-2-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-2-hor-top;
}

.flip-scale-2-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-2-ver-right;
}

.flip-scale-2-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-2-hor-bottom;
}

.flip-scale-2-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-scale-2-ver-left;
}

.swing-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-fwd;
}

.swing-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-bck;
}

.swing-top-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-right-fwd;
}

.swing-top-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-right-bck;
}

.swing-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-right-fwd;
}

.swing-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-right-bck;
}

.swing-bottom-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-right-fwd;
}

.swing-bottom-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-right-bck;
}

.swing-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-fwd;
}

.swing-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-bck;
}

.swing-bottom-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-left-fwd;
}

.swing-bottom-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-bottom-left-bck;
}

.swing-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-left-fwd;
}

.swing-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-left-bck;
}

.swing-top-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-left-fwd;
}

.swing-top-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-top-left-bck;
}

.slide-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-top;
}

.slide-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-tr;
}

.slide-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-right;
}

.slide-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-br;
}

.slide-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bottom;
}

.slide-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bl;
}

.slide-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-left;
}

.slide-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-tl;
}

.slide-bck-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-center;
}

.slide-bck-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-top;
}

.slide-bck-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-tr;
}

.slide-bck-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-right;
}

.slide-bck-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-br;
}

.slide-bck-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-bottom;
}

.slide-bck-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-bl;
}

.slide-bck-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-left;
}

.slide-bck-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-bck-tl;
}

.slide-fwd-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-center;
}

.slide-fwd-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-top;
}

.slide-fwd-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-tr;
}

.slide-fwd-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-right;
}

.slide-fwd-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-br;
}

.slide-fwd-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-bottom;
}

.slide-fwd-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-bl;
}

.slide-fwd-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-left;
}

.slide-fwd-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-fwd-tl;
}

.slide-rotate-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-top;
}

.slide-rotate-hor-t-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-t-bck;
}

.slide-rotate-hor-t-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-t-fwd;
}

.slide-rotate-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-right;
}

.slide-rotate-ver-r-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-r-bck;
}

.slide-rotate-ver-r-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-r-fwd;
}

.slide-rotate-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-bottom;
}

.slide-rotate-hor-b-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-b-bck;
}

.slide-rotate-hor-b-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-hor-b-fwd;
}

.slide-rotate-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-left;
}

.slide-rotate-ver-l-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-l-bck;
}

.slide-rotate-ver-l-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-rotate-ver-l-fwd;
}

.shadow-drop-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-center;
}

.shadow-drop-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-top;
}

.shadow-drop-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-right;
}

.shadow-drop-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-bottom;
}

.shadow-drop-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-left;
}

.shadow-drop-lr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-lr;
}

.shadow-drop-tb {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-tb;
}

.shadow-drop-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-tr;
}

.shadow-drop-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-br;
}

.shadow-drop-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-bl;
}

.shadow-drop-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-tl;
}

.shadow-drop-2-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-center;
}

.shadow-drop-2-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-top;
}

.shadow-drop-2-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-right;
}

.shadow-drop-2-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-bottom;
}

.shadow-drop-2-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-left;
}

.shadow-drop-2-lr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-lr;
}

.shadow-drop-2-tb {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-tb;
}

.shadow-drop-2-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-tr;
}

.shadow-drop-2-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-br;
}

.shadow-drop-2-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-bl;
}

.shadow-drop-2-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-tl;
}

.shadow-pop-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-pop-tr;
}

.shadow-pop-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-pop-br;
}

.shadow-pop-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-pop-bl;
}

.shadow-pop-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-pop-tl;
}

.shadow-inset-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-center;
}

.shadow-inset-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-top;
}

.shadow-inset-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-right;
}

.shadow-inset-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-bottom;
}

.shadow-inset-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-left;
}

.shadow-inset-lr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-lr;
}

.shadow-inset-tb {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-tb;
}

.shadow-inset-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-tr;
}

.shadow-inset-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-br;
}

.shadow-inset-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-bl;
}

.shadow-inset-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shadow-inset-tl;
}

.scale-in-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-center;
}

.scale-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-top;
}

.scale-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-tr;
}

.scale-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-right;
}

.scale-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-br;
}

.scale-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-bottom;
}

.scale-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-bl;
}

.scale-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-left;
}

.scale-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-tl;
}

.scale-in-hor-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-hor-center;
}

.scale-in-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-hor-left;
}

.scale-in-hor-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-hor-right;
}

.scale-in-ver-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-ver-center;
}

.scale-in-ver-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-ver-top;
}

.scale-in-ver-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-ver-bottom;
}

.rotate-in-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-center;
}

.rotate-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-top;
}

.rotate-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-tr;
}

.rotate-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-right;
}

.rotate-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-br;
}

.rotate-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-bottom;
}

.rotate-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-bl;
}

.rotate-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-left;
}

.rotate-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-tl;
}

.rotate-in-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-hor;
}

.rotate-in-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-ver;
}

.rotate-in-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-diag-1;
}

.rotate-in-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-diag-2;
}

.rotate-in-2-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-cw;
}

.rotate-in-2-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-ccw;
}

.rotate-in-2-fwd-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-fwd-cw;
}

.rotate-in-2-fwd-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-fwd-ccw;
}

.rotate-in-2-bck-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-bck-cw;
}

.rotate-in-2-bck-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-bck-ccw;
}

.rotate-in-2-tr-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-tr-cw;
}

.rotate-in-2-tr-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-tr-ccw;
}

.rotate-in-2-br-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-br-cw;
}

.rotate-in-2-br-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-br-ccw;
}

.rotate-in-2-bl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-bl-cw;
}

.rotate-in-2-bl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-bl-ccw;
}

.rotate-in-2-tl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-tl-cw;
}

.rotate-in-2-tl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-in-2-tl-ccw;
}

.swirl-in-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-fwd;
}

.swirl-in-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-bck;
}

.swirl-in-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-top-fwd;
}

.swirl-in-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-top-bck;
}

.swirl-in-tr-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-tr-fwd;
}

.swirl-in-tr-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-tr-bck;
}

.swirl-in-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-right-fwd;
}

.swirl-in-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-right-bck;
}

.swirl-in-br-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-br-fwd;
}

.swirl-in-br-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-br-bck;
}

.swirl-in-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-bottom-fwd;
}

.swirl-in-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-bottom-bck;
}

.swirl-in-bl-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-bl-fwd;
}

.swirl-in-bl-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-bl-bck;
}

.swirl-in-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-left-fwd;
}

.swirl-in-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-left-bck;
}

.swirl-in-tl-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-tl-fwd;
}

.swirl-in-tl-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-in-tl-bck;
}

.flip-in-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-hor-bottom;
}

.flip-in-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-hor-top;
}

.flip-in-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-ver-right;
}

.flip-in-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-ver-left;
}

.flip-in-diag-1-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-diag-1-tr;
}

.flip-in-diag-1-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-diag-1-bl;
}

.flip-in-diag-2-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-diag-2-tl;
}

.flip-in-diag-2-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-diag-2-br;
}

.slit-in-vertical {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-in-vertical;
}

.slit-in-horizontal {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-in-horizontal;
}

.slit-in-diagonal-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-in-diagonal-1;
}

.slit-in-diagonal-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-in-diagonal-2;
}

.slide-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

.slide-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-tr;
}

.slide-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-right;
}

.slide-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-br;
}

.slide-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bottom;
}

.slide-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bl;
}

.slide-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-left;
}

.slide-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-tl;
}

.slide-in-fwd-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-center;
}

.slide-in-fwd-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-top;
}

.slide-in-fwd-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-tr;
}

.slide-in-fwd-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-right;
}

.slide-in-fwd-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-br;
}

.slide-in-fwd-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-bottom;
}

.slide-in-fwd-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-bl;
}

.slide-in-fwd-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-left;
}

.slide-in-fwd-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-tl;
}

.slide-in-bck-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-center;
}

.slide-in-bck-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-top;
}

.slide-in-bck-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-tr;
}

.slide-in-bck-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-right;
}

.slide-in-bck-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-br;
}

.slide-in-bck-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-bottom;
}

.slide-in-bck-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-bl;
}

.slide-in-bck-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-left;
}

.slide-in-bck-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bck-tl;
}

.slide-in-blurred-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-top;
}

.slide-in-blurred-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-tr;
}

.slide-in-blurred-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-right;
}

.slide-in-blurred-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-br;
}

.slide-in-blurred-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-bottom;
}

.slide-in-blurred-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-bl;
}

.slide-in-blurred-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-left;
}

.slide-in-blurred-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-blurred-tl;
}

.slide-in-elliptic-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-top-fwd;
}

.slide-in-elliptic-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-top-bck;
}

.slide-in-elliptic-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-right-fwd;
}

.slide-in-elliptic-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-right-bck;
}

.slide-in-elliptic-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-bottom-fwd;
}

.slide-in-elliptic-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-bottom-bck;
}

.slide-in-elliptic-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-left-fwd;
}

.slide-in-elliptic-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-left-bck;
}

.bounce-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-top;
}

.bounce-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-right;
}

.bounce-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-bottom;
}

.bounce-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-left;
}

.bounce-in-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-fwd;
}

.bounce-in-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-in-bck;
}

.roll-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-left;
}

.roll-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-top;
}

.roll-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-right;
}

.roll-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-bottom;
}

.roll-in-blurred-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-blurred-left;
}

.roll-in-blurred-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-blurred-top;
}

.roll-in-blurred-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-blurred-right;
}

.roll-in-blurred-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-in-blurred-bottom;
}

.tilt-in-top-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-top-1;
}

.tilt-in-top-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-top-2;
}

.tilt-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-tr;
}

.tilt-in-right-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-right-1;
}

.tilt-in-right-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-right-2;
}

.tilt-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-br;
}

.tilt-in-bottom-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-bottom-1;
}

.tilt-in-bottom-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-bottom-2;
}

.tilt-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-bl;
}

.tilt-in-left-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-left-1;
}

.tilt-in-left-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-left-2;
}

.tilt-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-tl;
}

.tilt-in-fwd-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-fwd-tr;
}

.tilt-in-fwd-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-fwd-br;
}

.tilt-in-fwd-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-fwd-bl;
}

.tilt-in-fwd-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tilt-in-fwd-tl;
}

.swing-in-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-top-fwd;
}

.swing-in-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-top-bck;
}

.swing-in-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-right-fwd;
}

.swing-in-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-right-bck;
}

.swing-in-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-bottom-fwd;
}

.swing-in-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-bottom-bck;
}

.swing-in-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-left-fwd;
}

.swing-in-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-in-left-bck;
}

.fade-in {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in;
}

.fade-in-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-fwd;
}

.fade-in-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-bck;
}

.fade-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-top;
}

.fade-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-tr;
}

.fade-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-right;
}

.fade-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-br;
}

.fade-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-bottom;
}

.fade-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-bl;
}

.fade-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-left;
}

.fade-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-in-tl;
}

.puff-in-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-center;
}

.puff-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-top;
}

.puff-in-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-tr;
}

.puff-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-right;
}

.puff-in-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-br;
}

.puff-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-bottom;
}

.puff-in-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-bl;
}

.puff-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-left;
}

.puff-in-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-tl;
}

.puff-in-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-hor;
}

.puff-in-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-in-ver;
}

.flicker-in-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-in-1;
}

.flicker-in-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-in-2;
}

.scale-out-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-center;
}

.scale-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-top;
}

.scale-out-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-tr;
}

.scale-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-right;
}

.scale-out-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-br;
}

.scale-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-bottom;
}

.scale-out-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-bl;
}

.scale-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-left;
}

.scale-out-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-tl;
}

.scale-out-horizontal {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-horizontal;
}

.scale-out-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-hor-left;
}

.scale-out-hor-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-hor-right;
}

.scale-out-vertical {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-vertical;
}

.scale-out-ver-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-ver-top;
}

.scale-out-ver-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-out-ver-bottom;
}

.rotate-out-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-center;
}

.rotate-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-top;
}

.rotate-out-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-tr;
}

.rotate-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-right;
}

.rotate-out-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-br;
}

.rotate-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-bottom;
}

.rotate-out-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-bl;
}

.rotate-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-left;
}

.rotate-out-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-tl;
}

.rotate-out-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-hor;
}

.rotate-out-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-ver;
}

.rotate-out-diag-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-diag-1;
}

.rotate-out-diag-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-diag-2;
}

.rotate-out-2-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-cw;
}

.rotate-out-2-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-ccw;
}

.rotate-out-2-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-bck;
}

.rotate-out-2-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-fwd;
}

.rotate-out-2-tr-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-tr-cw;
}

.rotate-out-2-tr-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-tr-ccw;
}

.rotate-out-2-br-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-br-cw;
}

.rotate-out-2-br-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-br-ccw;
}

.rotate-out-2-bl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-bl-cw;
}

.rotate-out-2-bl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-bl-ccw;
}

.rotate-out-2-tl-cw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-tl-cw;
}

.rotate-out-2-tl-ccw {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both rotate-out-2-tl-ccw;
}

.swirl-out-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-bck;
}

.swirl-out-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-fwd;
}

.swirl-out-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-top-bck;
}

.swirl-out-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-top-fwd;
}

.swirl-out-tr-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-tr-bck;
}

.swirl-out-tr-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-tr-fwd;
}

.swirl-out-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-right-bck;
}

.swirl-out-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-right-fwd;
}

.swirl-out-br-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-br-bck;
}

.swirl-out-br-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-br-fwd;
}

.swirl-out-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-bottom-bck;
}

.swirl-out-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-bottom-fwd;
}

.swirl-out-bl-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-bl-bck;
}

.swirl-out-bl-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-bl-fwd;
}

.swirl-out-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-left-bck;
}

.swirl-out-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-left-fwd;
}

.swirl-out-tl-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-tl-bck;
}

.swirl-out-tl-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swirl-out-tl-fwd;
}

.flip-out-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-hor-top;
}

.flip-out-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-hor-bottom;
}

.flip-out-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-ver-left;
}

.flip-out-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-ver-right;
}

.flip-out-diag-1-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-diag-1-tr;
}

.flip-out-diag-1-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-diag-1-bl;
}

.flip-out-diag-2-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-diag-2-tl;
}

.flip-out-diag-2-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-out-diag-2-br;
}

.slit-out-vertical {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-out-vertical;
}

.slit-out-horizontal {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-out-horizontal;
}

.slit-out-diagonal-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-out-diagonal-1;
}

.slit-out-diagonal-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slit-out-diagonal-2;
}

.slide-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-top;
}

.slide-out-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-tr;
}

.slide-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-right;
}

.slide-out-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-br;
}

.slide-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bottom;
}

.slide-out-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bl;
}

.slide-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-left;
}

.slide-out-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-tl;
}

.slide-out-bck-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-center;
}

.slide-out-bck-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-top;
}

.slide-out-bck-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-tr;
}

.slide-out-bck-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-right;
}

.slide-out-bck-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-br;
}

.slide-out-bck-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-bottom;
}

.slide-out-bck-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-bl;
}

.slide-out-bck-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-left;
}

.slide-out-bck-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-bck-tl;
}

.slide-out-fwd-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-center;
}

.slide-out-fwd-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-top;
}

.slide-out-fwd-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-tr;
}

.slide-out-fwd-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-right;
}

.slide-out-fwd-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-br;
}

.slide-out-fwd-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-bottom;
}

.slide-out-fwd-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-bl;
}

.slide-out-fwd-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-left;
}

.slide-out-fwd-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-fwd-tl;
}

.slide-out-blurred-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-top;
}

.slide-out-blurred-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-tr;
}

.slide-out-blurred-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-right;
}

.slide-out-blurred-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-br;
}

.slide-out-blurred-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-bottom;
}

.slide-out-blurred-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-bl;
}

.slide-out-blurred-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-left;
}

.slide-out-blurred-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-blurred-tl;
}

.slide-out-elliptic-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-top-bck;
}

.slide-out-elliptic-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-top-fwd;
}

.slide-out-elliptic-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-right-bck;
}

.slide-out-elliptic-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-right-fwd;
}

.slide-out-elliptic-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-bottom-bck;
}

.slide-out-elliptic-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-bottom-fwd;
}

.slide-out-elliptic-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-left-bck;
}

.slide-out-elliptic-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-out-elliptic-left-fwd;
}

.bounce-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-top;
}

.bounce-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-right;
}

.bounce-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-bottom;
}

.bounce-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-left;
}

.bounce-out-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-bck;
}

.bounce-out-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-out-fwd;
}

.roll-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-left;
}

.roll-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-top;
}

.roll-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-right;
}

.roll-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-bottom;
}

.roll-out-blurred-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-blurred-left;
}

.roll-out-blurred-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-blurred-top;
}

.roll-out-blurred-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-blurred-right;
}

.roll-out-blurred-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both roll-out-blurred-bottom;
}

.swing-out-top-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-top-bck;
}

.swing-out-top-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-top-fwd;
}

.swing-out-right-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-right-bck;
}

.swing-out-right-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-right-fwd;
}

.swing-out-bottom-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-bottom-bck;
}

.swing-out-bottom-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-bottom-fwd;
}

.swing-out-left-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-left-bck;
}

.swing-out-left-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both swing-out-left-fwd;
}

.fade-out {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out;
}

.fade-out-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-bck;
}

.fade-out-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-fwd;
}

.fade-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-top;
}

.fade-out-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-tr;
}

.fade-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-right;
}

.fade-out-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-br;
}

.fade-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-bottom;
}

.fade-out-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-bl;
}

.fade-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-left;
}

.fade-out-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both fade-out-tl;
}

.puff-out-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-center;
}

.puff-out-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-top;
}

.puff-out-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-tr;
}

.puff-out-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-right;
}

.puff-out-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-br;
}

.puff-out-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-bottom;
}

.puff-out-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-bl;
}

.puff-out-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-left;
}

.puff-out-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-tl;
}

.puff-out-hor {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-hor;
}

.puff-out-ver {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both puff-out-ver;
}

.flicker-out-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-out-1;
}

.flicker-out-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-out-2;
}

.tracking-in-expand {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-expand;
}

.tracking-in-expand-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-expand-fwd;
}

.tracking-in-expand-fwd-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-expand-fwd-top;
}

.tracking-in-expand-fwd-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-expand-fwd-bottom;
}

.tracking-in-contract {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-contract;
}

.tracking-in-contract-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-contract-bck;
}

.tracking-in-contract-bck-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-contract-bck-top;
}

.tracking-in-contract-bck-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-in-contract-bck-bottom;
}

.tracking-out-contract {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-contract;
}

.tracking-out-contract-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-contract-bck;
}

.tracking-out-contract-bck-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-contract-bck-top;
}

.tracking-out-contract-bck-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-contract-bck-bottom;
}

.tracking-out-expand {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-expand;
}

.tracking-out-expand-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-expand-fwd;
}

.tracking-out-expand-fwd-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-expand-fwd-top;
}

.tracking-out-expand-fwd-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both tracking-out-expand-fwd-bottom;
}

.text-focus-in {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-focus-in;
}

.focus-in-expand {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both focus-in-expand;
}

.focus-in-expand-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both focus-in-expand-fwd;
}

.focus-in-contract {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both focus-in-contract;
}

.focus-in-contract-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both focus-in-contract-bck;
}

.text-blur-out {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-blur-out;
}

.blur-out-contract {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blur-out-contract;
}

.blur-out-contract-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blur-out-contract-bck;
}

.blur-out-expand {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blur-out-expand;
}

.blur-out-expand-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blur-out-expand-fwd;
}

.text-flicker-in-glow {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-flicker-in-glow;
}

.text-flicker-out-glow {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-flicker-out-glow;
}

.text-shadow-drop-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-center;
}

.text-shadow-drop-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-top;
}

.text-shadow-drop-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-tr;
}

.text-shadow-drop-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-right;
}

.text-shadow-drop-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-br;
}

.text-shadow-drop-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-bottom;
}

.text-shadow-drop-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-bl;
}

.text-shadow-drop-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-left;
}

.text-shadow-drop-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-drop-tl;
}

.text-shadow-pop-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-top;
}

.text-shadow-pop-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-tr;
}

.text-shadow-pop-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-right;
}

.text-shadow-pop-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-br;
}

.text-shadow-pop-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-bottom;
}

.text-shadow-pop-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-bl;
}

.text-shadow-pop-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-left;
}

.text-shadow-pop-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-shadow-pop-tl;
}

.text-pop-up-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-top;
}

.text-pop-up-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-tr;
}

.text-pop-up-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-right;
}

.text-pop-up-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-br;
}

.text-pop-up-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-bottom;
}

.text-pop-up-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-bl;
}

.text-pop-up-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-left;
}

.text-pop-up-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-tl;
}

.vibrate-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both vibrate-1;
}

.vibrate-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both vibrate-2;
}

.vibrate-3 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both vibrate-3;
}

.flicker-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-1;
}

.flicker-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-2;
}

.flicker-3 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-3;
}

.flicker-4 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flicker-4;
}

.shake-horizontal {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-horizontal;
}

.shake-vertical {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-vertical;
}

.shake-lr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-lr;
}

.shake-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-top;
}

.shake-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-tr;
}

.shake-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-right;
}

.shake-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-br;
}

.shake-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-bottom;
}

.shake-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-bl;
}

.shake-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-left;
}

.shake-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both shake-tl;
}

.jello-horizontal {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both jello-horizontal;
}

.jello-vertical {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both jello-vertical;
}

.jello-diagonal-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both jello-diagonal-1;
}

.jello-diagonal-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both jello-diagonal-2;
}

.wobble-hor-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both wobble-hor-bottom;
}

.wobble-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both wobble-hor-top;
}

.wobble-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both wobble-ver-left;
}

.wobble-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both wobble-ver-right;
}

.bounce-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-top;
}

.bounce-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-bottom;
}

.bounce-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-left;
}

.bounce-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bounce-right;
}

.heartbeat {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both heartbeat;
}

.pulsate-bck {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both pulsate-bck;
}

.pulsate-fwd {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both pulsate-fwd;
}

.ping {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both ping;
}

.blink-1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blink-1;
}

.blink-2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both blink-2;
}

.kenburns-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-top;
}

.kenburns-top-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-top-right;
}

.kenburns-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-right;
}

.kenburns-bottom-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-bottom-right;
}

.kenburns-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-bottom;
}

.kenburns-bottom-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-bottom-left;
}

.kenburns-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-left;
}

.kenburns-top-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both kenburns-top-left;
}

.bg-pan-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-left;
}

.bg-pan-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-right;
}

.bg-pan-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-top;
}

.bg-pan-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-bottom;
}

.bg-pan-tr {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-tr;
}

.bg-pan-br {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-br;
}

.bg-pan-bl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-bl;
}

.bg-pan-tl {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both bg-pan-tl;
}

.color-change-2x {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both color-change-2x;
}

.color-change-3x {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both color-change-3x;
}

.color-change-4x {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both color-change-4x;
}

.color-change-5x {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both color-change-5x;
}
/*# sourceMappingURL=index.2a669b1d.css.map */
